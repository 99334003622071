
import { defineComponent } from "vue";
import { Empleador } from "@/interfaces/Empleador";
// import {
//   deleteEmpleador,
//   getEmpleador,
//   updateEmpleador,
// } from "@/services/empleador/Empleador";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "empleador-list",
  data() {
    return {
      cargando: false,
      empleador: {} as Empleador,
    };
  },
  methods: {
    toPrint() {
      window.print();
    },
    toggleLoading() {
      this.cargando = !this.cargando;
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("D/MM/yyyy HH:mm");
    },

    formatDate2(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("D/MM/yyyy");
    },

    // async loadEmpleador(id: string) {
    //   this.toggleLoading();
    //   try {
    //     const { data } = await getEmpleador(id);
    //     this.empleador = data;
    //   } catch (error) {
    //     //console.error(error);
    //   }
    //   await this.toggleLoading();
    //   this.toPrint();

    //   if (this.$store.state.ids.length == 0) {
    //     this.$router.push(`/empleadors/${this.empleador._id}`);
    //   } else {
    //     this.$router.push(`/empleadorslot/new`);
    //   }

    //   // this.$router.push(`/empleadors/${this.empleador._id}`);
    // },
    // async handleUpdate() {
    //   try {
    //     if (typeof this.$route.params.id === "string") {
    //       await updateEmpleador(this.$route.params.id, this.empleador);
    //       this.$router.push("/empleadors");
    //     }
    //   } catch (error) {
    //     //console.error(error);
    //   }
    // },
    // async handleDelete() {
    //   try {
    //     if (typeof this.$route.params.id === "string") {
    //       deleteEmpleador(this.$route.params.id);
    //       this.$router.push("/empleadors");
    //     }
    //   } catch (error) {
    //     //console.error(error);
    //   }
    // },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadEmpleador(this.$route.params.id);
    }
  },

  // beforeUnmount() {
  //   this.toPrint();
  // }
});
