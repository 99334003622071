
import Filters from "@/components/Centro/FiltersComponent.vue";
import ListadoComponent from "@/components/Centro/ListComponent.vue";
import Navbar from "@/components/Navbar.vue";
import { Centro } from "@/interfaces/Centro";
import { getAll } from "@/services/centro/Centro";
export default {
  name: "App",

  components: {
    Filters,
    ListadoComponent,
    Navbar,
  },

  data() {
    return {
      player: new Audio(),
      showModal: false,
      cargando: false,
      centros: [] as Centro[],
      str: "",
      type: "",
    };
  },

  methods: {
    showModalMethod() {
      this.showModal = !this.showModal;
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    async filterCentros(catName: string) {
      try {
        const res = await getAll();
        this.centros = res.data;
      } catch (error) {
        console.error(error);
      }
      if (catName !== "Todos") {
        this.centros = this.centros.filter((centro: Centro) => {
          return centro.unap === catName;
        });
      }
    },

    async search(term: string) {
      this.toggleLoading();
      try {
        const res = await getAll();
        this.centros = res.data;
      } catch (error) {
        console.error(error);
      }
      if (term !== "Todos") {
        this.centros = this.centros.filter((centro: Centro) => {
          return centro.unap.toLowerCase().includes(term.toLowerCase());
        });
      }
      this.toggleLoading();
    },

    async loadCentros() {
      this.toggleLoading();
      try {
        const res = await getAll();
        this.centros = res.data.centros;
        this.$store.state.user.proxCodCentro = res.data.centros.length;
      } catch (error) {
        console.error(error);
      }
      this.toggleLoading();
    },

    async loadCentros2() {
      this.toggleLoading();
      try {
        const res = await getAll();
        this.centros = res.data;
      } catch (error) {
        console.error(error);
      }
      this.toggleLoading();
    },
  },

  mounted() {
    this.loadCentros();
  },
};
