
export default {
  props: ["filterCentros", "search", "filteredCentros"],

  data() {
    return {
      term: "",
      filters2: [] as [],
    };
  },

};
