import { createRouter, createWebHistory } from "vue-router";

import store from "../store";

import GuestLayout from "../layouts/GuestLayout.vue";
import LogIn from "../views/LogInView.vue";
import SignUp from "../views/SignUpView.vue";

import DashboardLayout from "../layouts/DashboardLayout.vue";
import Dashboard from "../views/DashboardView.vue";

import Perfil from "../views/PerfilView.vue";


// Gcorporativo -------------------------------------------------------------------
import GcorporativoDetailComponent from "../components/Gcorporativo/FormComp.vue";

// Empleador -------------------------------------------------------------------
import EmpleadorComponent from "../components/Empleador/PrincipalComponent.vue";
import EmpleadorDetailComponent from "../components/Empleador/FormComp.vue";
import EmpleadorPrintComponent from "../components/Empleador/PrintComponent.vue";
import EmpleadorFormComp from "../components/Empleador/FormComp.vue";
// --------------------------------------------------------------------------
// Empleado -------------------------------------------------------------------
import EmpleadoComponent from "../components/Empleado/PrincipalComponent.vue";
import EmpleadoDetailComponent from "../components/Empleado/FormComp.vue";
import EmpleadoPrintComponent from "../components/Empleado/PrintComponent.vue";
import EmpleadoFormComp from "../components/Empleado/FormComp.vue";
// --------------------------------------------------------------------------

// Centro -------------------------------------------------------------------
import CentroComponent from "../components/Centro/PrincipalComponent.vue";
import CentroDetailComponent from "../components/Centro/FormComp.vue";
import CentroPrintComponent from "../components/Centro/PrintComponent.vue";
import CentroFormComp from "../components/Centro/FormComp.vue";
// --------------------------------------------------------------------------

// Evaluacion -------------------------------------------------------------------

import CompleteFormComp from "../components/Evaluacion/CompleteFormComp.vue";
import SeguiFormComp from "../components/Evaluacion/SeguiFormComp.vue";
import OpenEvaluacionComponent from "../components/Evaluacion/OpenFormComp.vue";
import EvaluacionComponent from "../components/Evaluacion/PrincipalComponent.vue";
import EvaluacionDetailComponent from "../components/Evaluacion/FormComp.vue";
import EvaluacionPrintComponent from "../components/Evaluacion/PrintComponent.vue";
import EvaluacionFormComp from "../components/Evaluacion/FormComp.vue";

const routes = [
  {
    path: "/",
    component: GuestLayout,
    children: [
      {
        path: "/",
        name: "LogIn",
        component: LogIn,
      },
      {
        path: "/signup",
        name: "SignUp",
        component: SignUp,
      },
    ],
  },
  {
    path: "/dashboard",
    component: DashboardLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          roles: ["USUARIO"],
        },
      },

      // Grupo Corporativo --------------------------------------------------------------------
      {
        path: "/grpcorps/:id",
        name: "grpcorps-details",
        component: GcorporativoDetailComponent,
        meta: {
          roles: ["USUARIO"],
        },
      },

      // Empleador --------------------------------------------------------------------
      {
        path: "/empleadors",
        name: "empleadors",
        component: EmpleadorComponent,
        meta: {
          roles: ["USUARIO"],
        },
      },
      {
        path: "/empleadors/:id",
        name: "empleador-details",
        component: EmpleadorDetailComponent,
        meta: {
          roles: ["USUARIO"],
        },
      },
      {
        path: "/empleadors2/:id",
        name: "empleador-details2",
        component: EmpleadorPrintComponent,
        meta: {
          roles: ["USUARIO"],
        },
      },
      {
        path: "/empleadors/new",
        name: "empleadors-form",
        component: EmpleadorFormComp,
        meta: {
          roles: ["USUARIO"],
        },
      },
      // Empleado --------------------------------------------------------------------
      {
        path: "/empleados",
        name: "empleados",
        component: EmpleadoComponent,
        meta: {
          roles: ["USUARIO"],
        },
      },
      {
        path: "/empleados/:id",
        name: "empleado-details",
        component: EmpleadoDetailComponent,
        meta: {
          roles: ["USUARIO"],
        },
      },
      {
        path: "/empleados2/:id",
        name: "empleado-details2",
        component: EmpleadoPrintComponent,
        meta: {
          roles: ["USUARIO"],
        },
      },
      {
        path: "/empleados/new",
        name: "empleados-form",
        component: EmpleadoFormComp,
        meta: {
          roles: ["USUARIO"],
        },
      },

      // Centro --------------------------------------------------------------------
      {
        path: "/centros",
        name: "centros",
        component: CentroComponent,
        meta: {
          roles: ["Gerente de Operaciones"],
        },
      },
      {
        path: "/centros/:id",
        name: "centro-details",
        component: CentroDetailComponent,
        meta: {
          roles: ["Gerente de Operaciones"],
        },
      },
      {
        path: "/centros2/:id",
        name: "centro-details2",
        component: CentroPrintComponent,
        meta: {
          roles: ["Gerente de Operaciones"],
        },
      },
      {
        path: "/centros/new",
        name: "centros-form",
        component: CentroFormComp,
        meta: {
          roles: ["Gerente de Operaciones"],
        },
      },

      // Evaluacion --------------------------------------------------------------------
      {
        path: "/openEvaluacions",
        name: "openEvaluacions",
        component: OpenEvaluacionComponent,
        meta: {
          roles: ["Supervisor en Sitio"],
        },
      },

      {
        path: "/evaluacions",
        name: "evaluacions",
        component: EvaluacionComponent,
        meta: {
          roles: ["Supervisor en Sitio", "Médico Nutricionista"],
        },
      },
      {
        path: "/evaluacions/:index",
        name: "evaluacion-details",
        component: SeguiFormComp,
        meta: {
          roles: ["Supervisor en Sitio"],
        },
      },
      {
        path: "/evaluacions/complete",
        name: "evaluacion-complete",
        component: CompleteFormComp,
        meta: {
          roles: ["Médico Nutricionista"],
        },
      },
      {
        path: "/evaluacions2/:id",
        name: "evaluacion-details2",
        component: EvaluacionPrintComponent,
        meta: {
          roles: ["Power User", "Encargado de Cuentas Médicas", "NUTRIMED"],
        },
      },
      {
        path: "/evaluacions/new",
        name: "evaluacions-form",
        component: EvaluacionFormComp,
        meta: {
          roles: ["Power User", "Encargado de Cuentas Médicas", "NUTRIMED"],
        },
      },
      {
        path: "/miPerfil",
        name: "miPerfil",
        component: Perfil,
        meta: {
          roles: ["USUARIO"],
        },
      },

      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          roles: ["USUARIO"],
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(to)
  if (to.meta?.requiresAuth && store.state.isAuthenticated) {
    // let users enter if authenticated
    if (to.meta?.roles?.includes(store.state.user.type)) {
      next();
    } else {
      // console.log('User is not authorized for route.')
      alert("Usuario no Autorizado.");
    }
  } else if (to.meta?.requiresAuth) {
    // otherwise, route them to /login
    next("/");
  } else {
    next();
  }
});

export default router;
