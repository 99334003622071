
import Filters from "@/components/Empleador/FiltersComponent.vue";
import ListadoComponent from "@/components/Empleador/ListComponent.vue";
import Navbar from "@/components/Navbar.vue";
import { Empleador } from "@/interfaces/Empleador";
import { getAll, getByGCorp } from "@/services/empleador/Empleador";
export default {
  name: "App",

  components: {
    Filters,
    ListadoComponent,
    Navbar,
  },

  data() {
    return {
      player: new Audio(),
      showModal: false,
      cargando: false,
      empleadors: [] as Empleador[],
      str: "",
      type: "",
    };
  },

  methods: {
    showModalMethod() {
      this.showModal = !this.showModal;
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    async filterEmpleadors(catName: string) {
      try {
        const res = await getAll();
        this.empleadors = res.data;
      } catch (error) {
        console.error(error);
      }
      if (catName !== "Todos") {
        this.empleadors = this.empleadors.filter((empleador: Empleador) => {
          return empleador.unap === catName;
        });
      }
    },

    async search(term: string) {
      this.toggleLoading();
      try {
        const res = await getAll();
        this.empleadors = res.data;
      } catch (error) {
        console.error(error);
      }
      if (term !== "Todos") {
        this.empleadors = this.empleadors.filter((empleador: Empleador) => {
          return empleador.unap.toLowerCase().includes(term.toLowerCase());
        });
      }
      this.toggleLoading();
    },

    async loadEmpleadors() {
      this.toggleLoading();
      try {
        const res = await getByGCorp(this.$store.state.user.gcorp);
        // alert(JSON.stringify(res.data.response.Items))
        this.empleadors = res.data.response.Items;
        // this.$store.state.user.proxCodEmpleador = res.data.empleadors.length;
      } catch (error) {
        console.error(error);
      }
      this.toggleLoading();
    },

    async loadEmpleadors2() {
      this.toggleLoading();
      try {
        const res = await getAll();
        this.empleadors = res.data;
      } catch (error) {
        console.error(error);
      }
      this.toggleLoading();
    },
  },

  mounted() {
    this.loadEmpleadors();
  },
};
