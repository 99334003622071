
import { getAll } from "@/services/entities/Entity";
import Navbar from "@/components/Navbar.vue";
import { updateUsuario } from "@/services/usuario/UsuarioService";
import { getDash } from "@/services/dashboard/dashboardService";
import numeral from "numeral";
import Pusher from "pusher-js";
import moment from "moment";
import { generateUploadURL } from "./s3.js";
export default {
  name: "DashboardView",
  components: {
    Navbar,
  },

  data() {
    return {
      atributes: [] as any,
      currentEntity: {} as any,
      contribuyente: {} as any,
      noEmple: 0,
      entidades: ["GRUPO CORPORATIVO", "EMPLEADOR", "EMPLEADO"],
      polyline_points:
        "50,300 100,167.5 150,100 250,80 350,160 450,100 550,100 650,150 750,200 750,300",
      imageUrl: "",
      dias: [] as any,
      presupuesto: 400000,
      presupuesto2: 2335000,
      usuariosCant: [] as any,
      facturasCant: [] as any,
      factsGpedByCli: [] as any,
      mejorasPend: [] as any,
      totales: {} as any,
      cargando: false,
    };
  },

  async mounted() {
    await this.loadentidades();
  },

  methods: {
    async loadentidades() {
      this.toggleLoading();
      try {
        const res = await getAll();
        this.entities = res.data.entities;
        this.entities = this.entities.filter((entity: any) => {
          return entity.show == true;
        });
      } catch (error) {
        console.error(error);
      }
      this.toggleLoading();
      // let length = this.entities.length
      // let index = length - 1
      // this.currentEntity = this.entities[index]
      // this.atributes = Object.keys(this.currentEntity)
    },

    async update() {
      // this.toggleLoading();
      try {
        const res = await updateUsuario(this.$store.state.user.currentUsuario);
        // if (res.data.response.Count == 0) {
        //   this.fillFields();
        // } else {
        //   this.empleador = res.data.response.Items[0];
        // }
      } catch (error) {
        console.error(error);
      }
      // this.toggleLoading();
    },

    // Start Image Upload
    selectImage() {
      this.$refs.fileInput.click();
    },

    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },

    pickFile2() {
      let input = this.$refs.fileInput;
      let file = input.files;
      const file2 = file[0];
    },

    async uploadFile() {
      this.toggleLoading();
      let input = this.$refs.fileInput;
      let file = input.files;
      const file2 = file[0];

      // const url = await getS3Url(this.$store.state.user.username);
      // console.log(url);

      await fetch(
        `https://nrgr420pxf.execute-api.us-east-1.amazonaws.com/dev/cruzjiminian-assets-bucket/usuario-${this.$store.state.user.username}.jpg`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: file2,
        }
      );
      this.toggleLoading();

      // if (
      //   confirm(
      //     "Los cambios tardan unos minutos en aplicarse, deberá reiniciar la sesión para aplicar los mismos, ¿Desea Continuar?"
      //   )
      // ) {
      //   this.$router.push("/");
      //   this.$store.dispatch("logoutAction");
      // }
    },

    // Finish Image Upload

    //   Dashboard(embeddedURL: any) {
    //   var containerDiv = document.getElementById("dashboardContainer");
    //   var options = {
    //     url: embeddedURL,
    //     container: containerDiv,
    //     scrolling: "yes",
    //     height: "850px",
    //     width: "100%"
    //   };
    //   this.dashboard = QuicksightEmbedding.embedDashboard(options);
    // }

    async GetDashboardURL() {
      const url = await getDash();
      alert(url);
    },
    getCx(index: any) {
      return this.$store.state.user.valores2[index];
    },

    getImg() {
      let cedulaAfiliadoActual = this.$store.state.user.defaultStatus[0].cedula;
      let urlPartOne = cedulaAfiliadoActual.slice(0, 3);
      let urlPartTwo = cedulaAfiliadoActual.slice(4, 6);
      let urlPartThree = cedulaAfiliadoActual.slice(6, 8);
      this.imageUrl = `http://www.arssenasa.gov.do/Sistemas/afiliacion/fotos/${urlPartOne}/${urlPartTwo}/${urlPartThree}/${cedulaAfiliadoActual}.jpg`;
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("D/MM/yyyy");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("D/MM/yyyy");
    },

    numeroAFecha(numeroDeDias: any, esExcel = false) {
      var diasDesde1900 = esExcel ? 25567 + 1 : 25567 + 2;

      // 86400 es el número de segundos en un día, luego multiplicamos por 1000 para obtener milisegundos.
      return new Date((numeroDeDias - diasDesde1900) * 86400 * 1000);
    },

    formatTable(dif: number) {
      if (dif < 0) {
        return "der red";
      } else {
        return "der green";
      }
    },

    getMonth(month: number) {
      switch (month) {
        case 1:
          return "Enero";
        case 2:
          return "Febrero";
        case 3:
          return "Marzo";
        case 4:
          return "Abril";
        case 5:
          return "Mayo";
        case 6:
          return "Junio";
        case 7:
          return "Julio";
        case 8:
          return "Agosto";
        case 9:
          return "Septiembre";
        case 10:
          return "Octubre";
        case 11:
          return "Noviembre";
        case 12:
          return "Diciembre";

        default:
          return "Error";
      }
    },
    getARS(id_ars: string) {
      switch (id_ars) {
        case "5":
          return "ARS CMD";
        case "14":
          return "MAPFRE SALUD ARS, S.A.";
        case "8":
          return "FUTURO";
        case "12":
          return "META SALUD";
        case "23":
          return "YUNEN";
        case "17":
          return "RESERVAS";
        case "13":
          return "MONUMENTAL";
        case "4":
          return "ASEMAP";
        case "1":
          return "ARS APS S A";
        case "21":
          return "SIMAG";
        case "16":
          return "RENACER";
        case "59":
          return "GRUPO MEDICO ASOCIADO";
        case "9":
          return "GRUPO MEDICO ASOCIADO";
        case "24":
          return "PRIMERA  ARS DE HUMANO";
        case "22":
          return "UNIVERSAL";
        case "29":
          return "ALBA GAS S.R.L.";
        case "10":
          return "HUMANO SEGUROS";
        case "20":
          return "SENASA CONTRIBUTIVO";
        case "61":
          return "SENASA SUBSIDIADO";
        case "18":
          return "SEMMA";
        case "65":
          return "IDOPPRIL";
        default:
          return "ARS Descripcion";
      }
    },

    toColor(type: string) {
      if (type == "1 - Recibido por Auditoría Interna") {
        return "valor1";
      } else if (type == "2 - Verificado por Auditoría Interna") {
        return "valor2";
      } else if (type == "3 - Verificado por Auditoría Externa") {
        return "valor3";
      } else if (type == "4 - Recibido por Reclamaciones Médicas") {
        return "valor4";
      } else if (type == "5 - Verificado por Reclamaciones Médicas") {
        return "valor5";
      } else if (type == "6 - Cargado a Lote") {
        return "valor6";
      } else if (type == "7 - Enviado a la Aseguradora") {
        return "valor7";
      } else if (type == "Todos") {
        return "Todos";
      }
    },

    valorTotal() {
      this.totales.dias = this.dias.length;
      this.totales.cantProductos = this.dias.reduce(
        (accum: any, item: any) => accum + item.count,
        0
      );

      // this.totales.count = this.periodos.reduce(
      //   (accum: any, item: any) => accum + item.count,
      //   0
      // );
      // this.totales.cobertura = this.periodos.reduce(
      //   (accum: any, item: any) => accum + item.cobertura,
      //   0
      // );
      // this.totales.facturas = this.facturasCant.reduce(
      //   (accum: any, item: any) => accum + item.count,
      //   0
      // );
      // this.totales.facturas2 = this.factsGpedByCli.reduce(
      //   (accum: any, item: any) => accum + item.count,
      //   0
      // );
      // this.totales.cobertura2 = this.factsGpedByCli.reduce(
      //   (accum: any, item: any) => accum + item.cobertura,
      //   0
      // );
      // this.totales.cantClientes = this.factsGpedByCli.length;
    },

    pusherSubscribe() {
      // Start pusher subscribe
      var pusher = new Pusher("f2c972e55a9c9eac5f9a", {
        cluster: "us2",
      });

      var channel = pusher.subscribe("my-channel");
      channel.bind("my-event", (data: any) => {
        this.loadfacturasCant2();
        this.loadfactsGpedByCli2();
        // this.player.src = this.song.src;
        // this.player.play();
      });
      // End pusher subscribe
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    formatNumerInteger(value: number, decimal: boolean) {
      if (decimal == true) {
        return numeral(value).format("0");
      } else {
        return numeral(value).format("0");
      }
    },

    formatNumber(value: number, decimal: boolean) {
      if (decimal == true) {
        return numeral(value).format("0,0.00");
      } else {
        return numeral(value).format("0,0");
      }
    },

    formatNumberPcto(value: number, decimal: boolean) {
      if (decimal == true) {
        return numeral(value).format("0,0.00");
      } else {
        return numeral(value).format("0,0.00");
      }
    },
  },
};
