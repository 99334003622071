
import { registerUsuario } from "@/services/usuario/UsuarioService";
import { Usuario } from "@/interfaces/Usuario";
export default {
  name: "LoginView",
  components: {},

  data() {
    return {
      confirmPassword: "",
      passwordFieldType: "password",
      flexCheckDefault: false,
      cargando: false,
      error: "",
      usuario: {} as Usuario,
      respuesta: {},
      resp: {},
    };
  },

  updated() {
    if (this.usuario.name){this.usuario.name = this.usuario.name.toUpperCase()}
    if (this.usuario.email) {
      this.usuario.email = this.usuario.email.toUpperCase();
    }
    if (this.usuario.username) {
      this.usuario.username = this.usuario.username.toUpperCase();
    }
  },

  methods: {
    togglePasswordFieldType() {
      if (this.flexCheckDefault == false) {
        this.passwordFieldType = "password";
      } else {
        this.passwordFieldType = "text";
      }
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    async signup() {
      if (this.usuario.password == this.confirmPassword) {
        if (
          !this.usuario.name ||
          !this.usuario.email ||
          !this.usuario.username ||
          !this.usuario.password
        ) {
          this.error = "Todos los Campos son Requeridos";
        } else {
          if (this.usuario.password.length >= 8) {
            this.toggleLoading();
            this.usuario.role = "USUARIO"
            this.usuario.entities = []
            this.usuario.options = []
            const resp = await registerUsuario(this.usuario).then(
              (res) => {
                this.error = "";
                this.$router.push("/");
                this.resp = resp;
                this.respuesta = res;
              },
              (err) => {
                if (err.response.status === 401) {
                  this.error = err.response.data.message;
                } else {
                  this.error =
                    "Sorry.... the backend server is down!! please try again later";
                }
              }
            );
            this.toggleLoading();
          } else {
            alert("La contraseña debe tener 8 caracteres mínimo");
          }
        }
      } else {
        alert("Las Contraseñas no Coinciden");
      }
    },

    // login() {
    //   this.$store.dispatch("loginAction");
    //   this.$store.state.user.type = this.role;
    //   this.$router.push("/dashboard");
    // },
  },
};
