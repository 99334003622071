
export default {
  props: ["filterEmpleadors", "search", "filteredEmpleadors"],

  data() {
    return {
      term: "",
      filters2: [] as [],
    };
  },

};
