
import Navbar from "@/components/Navbar.vue";
import { create } from "@/services/centro/Centro";
import { defineComponent } from "vue";
import { Centro } from "@/interfaces/Centro";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "centros-form",
  components: {
    Navbar,
  },
  data() {
    return {
      municipios: [
        {
          municipio: "DISTRITO NACIONAL ",
          provincia: "DISTRITO NACIONAL ",
          region: "REGION A (0)",
        },
        {
          municipio: "MONTE PLATA",
          provincia: "MONTE PLATA ",
          region: "REGION A (0)",
        },
        {
          municipio: "BAYAGUANA",
          provincia: "MONTE PLATA ",
          region: "REGION A (0)",
        },
        {
          municipio: "PERALVILLO",
          provincia: "MONTE PLATA ",
          region: "REGION A (0)",
        },
        {
          municipio: "YAMASA",
          provincia: "MONTE PLATA ",
          region: "REGION A (0)",
        },
        {
          municipio: "SABANA GRANDE DE BOYA",
          provincia: "MONTE PLATA ",
          region: "REGION A (0)",
        },
        {
          municipio: "SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          region: "REGION A (0)",
        },
        {
          municipio: "SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          region: "REGION A (0)",
        },
        {
          municipio: "SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          region: "REGION A (0)",
        },
        {
          municipio: "LOS ALCARRIZOS",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          region: "REGION A (0)",
        },
        {
          municipio: "PEDRO BRAND",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          region: "REGION A (0)",
        },
        {
          municipio: "GUERRA",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          region: "REGION A (0)",
        },
        {
          municipio: "BOCA CHICA ",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          region: "REGION A (0)",
        },
        {
          municipio: "BANI",
          provincia: "PERAVIA",
          region: "REGION B (I)",
        },
        {
          municipio: "NIZAO",
          provincia: "PERAVIA",
          region: "REGION B (I)",
        },
        {
          municipio: "SAN CRISTOBAL",
          provincia: "SAN CRISTOBAL",
          region: "REGION B (I)",
        },
        {
          municipio: "SABANA GRANDE DE PALENQUE",
          provincia: "SAN CRISTOBAL",
          region: "REGION B (I)",
        },
        {
          municipio: "BAJOS DE HAINA",
          provincia: "SAN CRISTOBAL",
          region: "REGION B (I)",
        },
        {
          municipio: "GAMBITA GARABITOS",
          provincia: "SAN CRISTOBAL",
          region: "REGION B (I)",
        },
        {
          municipio: "VILLA ALTAGRACIA",
          provincia: "SAN CRISTOBAL",
          region: "REGION B (I)",
        },
        {
          municipio: "YAGUATE",
          provincia: "SAN CRISTOBAL",
          region: "REGION B (I)",
        },
        {
          municipio: "SAN GREGORIO DE NIGUA",
          provincia: "SAN CRISTOBAL",
          region: "REGION B (I)",
        },
        {
          municipio: "LOS CACAOS",
          provincia: "SAN CRISTOBAL",
          region: "REGION B (I)",
        },
        {
          municipio: "SAN JOSE DE OCOA",
          provincia: "SAN JOSE DE OCOA",
          region: "REGION B (I)",
        },
        {
          municipio: "RANCHO ARRIBA",
          provincia: "SAN JOSE DE OCOA",
          region: "REGION B (I)",
        },
        {
          municipio: "SABANA LARGA",
          provincia: "SAN JOSE DE OCOA",
          region: "REGION B (I)",
        },
        {
          municipio: "MOCA",
          provincia: "ESPALLIAT",
          region: "REGION C (II)",
        },
        {
          municipio: "CAYETANO GERMOSEN ",
          provincia: "ESPALLIAT",
          region: "REGION C (II)",
        },
        {
          municipio: "GASPAR HERNANDEZ",
          provincia: "ESPALLIAT",
          region: "REGION C (II)",
        },
        {
          municipio: "JAMAO AL NORTE ",
          provincia: "ESPALLIAT",
          region: "REGION C (II)",
        },
        {
          municipio: "SAN VICTOR",
          provincia: "ESPALLIAT",
          region: "REGION C (II)",
        },
        {
          municipio: "PUERTO PLATA ",
          provincia: "PUERTO PLATA",
          region: "REGION C (II)",
        },
        {
          municipio: "ALTAMIRA",
          provincia: "PUERTO PLATA",
          region: "REGION C (II)",
        },
        {
          municipio: "GUANANICO",
          provincia: "PUERTO PLATA",
          region: "REGION C (II)",
        },
        {
          municipio: "IMBERT",
          provincia: "PUERTO PLATA",
          region: "REGION C (II)",
        },
        {
          municipio: "LOS HIDALGOS ",
          provincia: "PUERTO PLATA",
          region: "REGION C (II)",
        },
        {
          municipio: "LUPERON ",
          provincia: "PUERTO PLATA",
          region: "REGION C (II)",
        },
        {
          municipio: "SOSUA ",
          provincia: "PUERTO PLATA",
          region: "REGION C (II)",
        },
        {
          municipio: "VILLA ISABELA",
          provincia: "PUERTO PLATA",
          region: "REGION C (II)",
        },
        {
          municipio: "VILLA MONTELLANO ",
          provincia: "PUERTO PLATA",
          region: "REGION C (II)",
        },
        {
          municipio: "SANTIAGO DE LOS CABALLEROS",
          provincia: "SANTIAGO",
          region: "REGION C (II)",
        },
        {
          municipio: "BAITOA",
          provincia: "SANTIAGO",
          region: "REGION C (II)",
        },
        {
          municipio: "HANICO",
          provincia: "SANTIAGO",
          region: "REGION C (II)",
        },
        {
          municipio: "LICEY AL MEDIO ",
          provincia: "SANTIAGO",
          region: "REGION C (II)",
        },
        {
          municipio: "PUNAL ",
          provincia: "SANTIAGO",
          region: "REGION C (II)",
        },
        {
          municipio: "SABAN IGLESIAS",
          provincia: "SANTIAGO",
          region: "REGION C (II)",
        },
        {
          municipio: "SAN JOSE DE LAS MATAS",
          provincia: "SANTIAGO",
          region: "REGION C (II)",
        },
        {
          municipio: "TAMBORIL ",
          provincia: "SANTIAGO",
          region: "REGION C (II)",
        },
        {
          municipio: "VILLA BISONO ",
          provincia: "SANTIAGO",
          region: "REGION C (II)",
        },
        {
          municipio: "VILLA GONZALES",
          provincia: "SANTIAGO",
          region: "REGION C (II)",
        },
        {
          municipio: "SAN FRANCISCO DE MACORIS",
          provincia: "DUARTE",
          region: "REGION D (III)",
        },
        {
          municipio: "ARENOSO ",
          provincia: "DUARTE",
          region: "REGION D (III)",
        },
        {
          municipio: "CASTILLO",
          provincia: "DUARTE",
          region: "REGION D (III)",
        },
        {
          municipio: "HOSTOS",
          provincia: "DUARTE",
          region: "REGION D (III)",
        },
        {
          municipio: "LAS GUARANAS",
          provincia: "DUARTE",
          region: "REGION D (III)",
        },
        {
          municipio: "PIMENTEL ",
          provincia: "DUARTE",
          region: "REGION D (III)",
        },
        {
          municipio: "VILLA ARRIBA",
          provincia: "DUARTE",
          region: "REGION D (III)",
        },
        {
          municipio: "SALCEDO",
          provincia: "HERMANAS MIRABAL",
          region: "REGION D (III)",
        },
        {
          municipio: "TENARES",
          provincia: "HERMANAS MIRABAL",
          region: "REGION D (III)",
        },
        {
          municipio: "VILLA TAPIA",
          provincia: "HERMANAS MIRABAL",
          region: "REGION D (III)",
        },
        {
          municipio: "NAGUA",
          provincia: "MARIA TRINIDAD SANCHEZ",
          region: "REGION D (III)",
        },
        {
          municipio: "CABRERA",
          provincia: "MARIA TRINIDAD SANCHEZ",
          region: "REGION D (III)",
        },
        {
          municipio: "EL FACTOR ",
          provincia: "MARIA TRINIDAD SANCHEZ",
          region: "REGION D (III)",
        },
        {
          municipio: "RIO SAN JUAN",
          provincia: "MARIA TRINIDAD SANCHEZ",
          region: "REGION D (III)",
        },
        {
          municipio: "SAMANA",
          provincia: "SAMANA ",
          region: "REGION D (III)",
        },
        {
          municipio: "LAS TERRENAS",
          provincia: "SAMANA ",
          region: "REGION D (III)",
        },
        {
          municipio: "SANCHEZ",
          provincia: "SAMANA ",
          region: "REGION D (III)",
        },
        {
          municipio: "NEIBA",
          provincia: "BAHORUCO",
          region: "REGION E (IV)",
        },
        {
          municipio: "GALVAN",
          provincia: "BAHORUCO",
          region: "REGION E (IV)",
        },
        {
          municipio: "LOS RIOS ",
          provincia: "BAHORUCO",
          region: "REGION E (IV)",
        },
        {
          municipio: "TAMAYO ",
          provincia: "BAHORUCO",
          region: "REGION E (IV)",
        },
        {
          municipio: "VILLA JARAGUA",
          provincia: "BAHORUCO",
          region: "REGION E (IV)",
        },
        {
          municipio: "BARAHONA",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "CABRAL ",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "EL PENON ",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "ENRIQUILLO",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "FUNDACION",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "JAQUIMEYES",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "LA CIENAGA",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "LAS SALINAS",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "PARAISO ",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "POLO ",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "VICENTE NOBLE",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "COMENDADOR ",
          provincia: "ELIAS PINA ",
          region: "REGION E (IV)",
        },
        {
          municipio: "BANICA",
          provincia: "ELIAS PINA ",
          region: "REGION E (IV)",
        },
        {
          municipio: "EL LLANO ",
          provincia: "ELIAS PINA ",
          region: "REGION E (IV)",
        },
        {
          municipio: "HONDO VALLE",
          provincia: "ELIAS PINA ",
          region: "REGION E (IV)",
        },
        {
          municipio: "JUAN SANTIAGO ",
          provincia: "ELIAS PINA ",
          region: "REGION E (IV)",
        },
        {
          municipio: "PEDRO SANTANA",
          provincia: "ELIAS PINA ",
          region: "REGION E (IV)",
        },
        {
          municipio: "JIMANI",
          provincia: "INDEPENDENCIA",
          region: "REGION E (IV)",
        },
        {
          municipio: "CRISTOBAL",
          provincia: "INDEPENDENCIA",
          region: "REGION E (IV)",
        },
        {
          municipio: "DUVERGE",
          provincia: "INDEPENDENCIA",
          region: "REGION E (IV)",
        },
        {
          municipio: "LA DESCUBIERTA",
          provincia: "INDEPENDENCIA",
          region: "REGION E (IV)",
        },
        {
          municipio: "MELLA",
          provincia: "INDEPENDENCIA",
          region: "REGION E (IV)",
        },
        {
          municipio: "POSTRER RIO ",
          provincia: "INDEPENDENCIA",
          region: "REGION E (IV)",
        },
        {
          municipio: "SANTA CRUZ DE EL SEIBO ",
          provincia: "EL SEIBO ",
          region: "REGION F (V)",
        },
        {
          municipio: "MICHES",
          provincia: "EL SEIBO ",
          region: "REGION F (V)",
        },
        {
          municipio: "HATO MAYOR DEL REY",
          provincia: "HATO MAYOR ",
          region: "REGION F (V)",
        },
        {
          municipio: "EL VALLE",
          provincia: "HATO MAYOR ",
          region: "REGION F (V)",
        },
        {
          municipio: "SABANA DE LA MAR",
          provincia: "HATO MAYOR ",
          region: "REGION F (V)",
        },
        {
          municipio: "HIGUEY",
          provincia: "LA ALTAGRACIA",
          region: "REGION F (V)",
        },
        {
          municipio: "SAN RAFAEL DE YUMA",
          provincia: "LA ALTAGRACIA",
          region: "REGION F (V)",
        },
        {
          municipio: "LA ROMANA",
          provincia: "LA ROMANA ",
          region: "REGION F (V)",
        },
        {
          municipio: "GUAYMATE",
          provincia: "LA ROMANA ",
          region: "REGION F (V)",
        },
        {
          municipio: "VILLA HERMOSA",
          provincia: "LA ROMANA ",
          region: "REGION F (V)",
        },
        {
          municipio: "SAN PEDRO DE MACORIS",
          provincia: "SAN PEDRO DE MACORIS",
          region: "REGION F (V)",
        },
        {
          municipio: "CONSUELO ",
          provincia: "SAN PEDRO DE MACORIS",
          region: "REGION F (V)",
        },
        {
          municipio: "GUAYACANES",
          provincia: "SAN PEDRO DE MACORIS",
          region: "REGION F (V)",
        },
        {
          municipio: "QUISQUEYA",
          provincia: "SAN PEDRO DE MACORIS",
          region: "REGION F (V)",
        },
        {
          municipio: "RAMON SANTANA",
          provincia: "SAN PEDRO DE MACORIS",
          region: "REGION F (V)",
        },
        {
          municipio: "SAN JOSE DE LOS LLANOS",
          provincia: "SAN PEDRO DE MACORIS",
          region: "REGION F (V)",
        },
        {
          municipio: "AZUA DE COMPOSTELA ",
          provincia: "AZUA",
          region: "REGION G(VI)",
        },
        {
          municipio: "ESTEBANIA",
          provincia: "AZUA",
          region: "REGION G(VI)",
        },
        {
          municipio: "GUAYABAL",
          provincia: "AZUA",
          region: "REGION G(VI)",
        },
        {
          municipio: "LAS CHARCAS",
          provincia: "AZUA",
          region: "REGION G(VI)",
        },
        {
          municipio: "LAS YAYAS DE VIAJAMA",
          provincia: "AZUA",
          region: "REGION G(VI)",
        },
        {
          municipio: "PADRE DE LAS CASAS",
          provincia: "AZUA",
          region: "REGION G(VI)",
        },
        {
          municipio: "PERALTA",
          provincia: "AZUA",
          region: "REGION G(VI)",
        },
        {
          municipio: "PUEBLO VIEJO ",
          provincia: "AZUA",
          region: "REGION G(VI)",
        },
        {
          municipio: "SABANA YEGUA ",
          provincia: "AZUA",
          region: "REGION G(VI)",
        },
        {
          municipio: "TABARA ARRIBA",
          provincia: "AZUA",
          region: "REGION G(VI)",
        },
        {
          municipio: "SAN JUAN DE LA MAGUANA",
          provincia: "SAN JUAN ",
          region: "REGION G(VI)",
        },
        {
          municipio: "BOECHIO",
          provincia: "SAN JUAN ",
          region: "REGION G(VI)",
        },
        {
          municipio: "EL CERCADO ",
          provincia: "SAN JUAN ",
          region: "REGION G(VI)",
        },
        {
          municipio: "JUAN DE HERRERA",
          provincia: "SAN JUAN ",
          region: "REGION G(VI)",
        },
        {
          municipio: "LAS MATAS DE FARFAN ",
          provincia: "SAN JUAN ",
          region: "REGION G(VI)",
        },
        {
          municipio: "VALLEJUELO ",
          provincia: "SAN JUAN ",
          region: "REGION G(VI)",
        },
        {
          municipio: "PEDERNALES ",
          provincia: "PEDERNALES ",
          region: "REGION G(VI)",
        },
        {
          municipio: "OVIEDO ",
          provincia: "PEDERNALES ",
          region: "REGION G(VI)",
        },
        {
          municipio: "DAJABON",
          provincia: "DAJABON",
          region: "REGION H(VII)",
        },
        {
          municipio: "EL PINO ",
          provincia: "DAJABON",
          region: "REGION H(VII)",
        },
        {
          municipio: "LOMA DE CABRERA",
          provincia: "DAJABON",
          region: "REGION H(VII)",
        },
        {
          municipio: "PARTIDO",
          provincia: "DAJABON",
          region: "REGION H(VII)",
        },
        {
          municipio: "RESTAURACION ",
          provincia: "DAJABON",
          region: "REGION H(VII)",
        },
        {
          municipio: "MONTE CRISTI ",
          provincia: "MONTE CRISTI ",
          region: "REGION H(VII)",
        },
        {
          municipio: "CASTANUELA",
          provincia: "MONTE CRISTI ",
          region: "REGION H(VII)",
        },
        {
          municipio: "GUAYUBIN ",
          provincia: "MONTE CRISTI ",
          region: "REGION H(VII)",
        },
        {
          municipio: "LAS MATAS DE SANTA CRUZ",
          provincia: "MONTE CRISTI ",
          region: "REGION H(VII)",
        },
        {
          municipio: "PEPILLO SALCEDO ",
          provincia: "MONTE CRISTI ",
          region: "REGION H(VII)",
        },
        {
          municipio: "VILLA VASQUEZ",
          provincia: "MONTE CRISTI ",
          region: "REGION H(VII)",
        },
        {
          municipio: "SAN IGNACIO DE SABANETA",
          provincia: "SANTIAGO RODRIGUEZ",
          region: "REGION H(VII)",
        },
        {
          municipio: "MONCION",
          provincia: "SANTIAGO RODRIGUEZ",
          region: "REGION H(VII)",
        },
        {
          municipio: "VILLA LOS ALMACIGOS",
          provincia: "SANTIAGO RODRIGUEZ",
          region: "REGION H(VII)",
        },
        {
          municipio: "MAO",
          provincia: "VALVERDE",
          region: "REGION H(VII)",
        },
        {
          municipio: "ESPERANZA",
          provincia: "VALVERDE",
          region: "REGION H(VII)",
        },
        {
          municipio: "LAGUNA SALADA",
          provincia: "VALVERDE",
          region: "REGION H(VII)",
        },
        {
          municipio: "LA VEGA",
          provincia: "LA VEGA",
          region: "REGION I (VIII)",
        },
        {
          municipio: "CONSTANZA",
          provincia: "LA VEGA",
          region: "REGION I (VIII)",
        },
        {
          municipio: "JARABACOA",
          provincia: "LA VEGA",
          region: "REGION I (VIII)",
        },
        {
          municipio: "JIMA ABAJO ",
          provincia: "LA VEGA",
          region: "REGION I (VIII)",
        },
        {
          municipio: "BONAO",
          provincia: "MONSENOR NOUEL",
          region: "REGION I (VIII)",
        },
        {
          municipio: "MAIMON ",
          provincia: "MONSENOR NOUEL",
          region: "REGION I (VIII)",
        },
        {
          municipio: "PIEDRA BLANCA",
          provincia: "MONSENOR NOUEL",
          region: "REGION I (VIII)",
        },
        {
          municipio: "COTUI",
          provincia: "SANCHEZ RAMIREZ",
          region: "REGION I (VIII)",
        },
        {
          municipio: "CEVICOS",
          provincia: "SANCHEZ RAMIREZ",
          region: "REGION I (VIII)",
        },
        {
          municipio: "FANTINO",
          provincia: "SANCHEZ RAMIREZ",
          region: "REGION I (VIII)",
        },
        {
          municipio: "LA MATA",
          provincia: "SANCHEZ RAMIREZ",
          region: "REGION I (VIII)",
        },
      ],
      municipiosFiltrados: [
        {
          municipio: "DISTRITO NACIONAL ",
          provincia: "DISTRITO NACIONAL ",
          region: "REGION A (0)",
        },
        {
          municipio: "MONTE PLATA",
          provincia: "MONTE PLATA ",
          region: "REGION A (0)",
        },
        {
          municipio: "BAYAGUANA",
          provincia: "MONTE PLATA ",
          region: "REGION A (0)",
        },
        {
          municipio: "PERALVILLO",
          provincia: "MONTE PLATA ",
          region: "REGION A (0)",
        },
        {
          municipio: "YAMASA",
          provincia: "MONTE PLATA ",
          region: "REGION A (0)",
        },
        {
          municipio: "SABANA GRANDE DE BOYA",
          provincia: "MONTE PLATA ",
          region: "REGION A (0)",
        },
        {
          municipio: "SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          region: "REGION A (0)",
        },
        {
          municipio: "SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          region: "REGION A (0)",
        },
        {
          municipio: "SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          region: "REGION A (0)",
        },
        {
          municipio: "LOS ALCARRIZOS",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          region: "REGION A (0)",
        },
        {
          municipio: "PEDRO BRAND",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          region: "REGION A (0)",
        },
        {
          municipio: "GUERRA",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          region: "REGION A (0)",
        },
        {
          municipio: "BOCA CHICA ",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          region: "REGION A (0)",
        },
        {
          municipio: "BANI",
          provincia: "PERAVIA",
          region: "REGION B (I)",
        },
        {
          municipio: "NIZAO",
          provincia: "PERAVIA",
          region: "REGION B (I)",
        },
        {
          municipio: "SAN CRISTOBAL",
          provincia: "SAN CRISTOBAL",
          region: "REGION B (I)",
        },
        {
          municipio: "SABANA GRANDE DE PALENQUE",
          provincia: "SAN CRISTOBAL",
          region: "REGION B (I)",
        },
        {
          municipio: "BAJOS DE HAINA",
          provincia: "SAN CRISTOBAL",
          region: "REGION B (I)",
        },
        {
          municipio: "GAMBITA GARABITOS",
          provincia: "SAN CRISTOBAL",
          region: "REGION B (I)",
        },
        {
          municipio: "VILLA ALTAGRACIA",
          provincia: "SAN CRISTOBAL",
          region: "REGION B (I)",
        },
        {
          municipio: "YAGUATE",
          provincia: "SAN CRISTOBAL",
          region: "REGION B (I)",
        },
        {
          municipio: "SAN GREGORIO DE NIGUA",
          provincia: "SAN CRISTOBAL",
          region: "REGION B (I)",
        },
        {
          municipio: "LOS CACAOS",
          provincia: "SAN CRISTOBAL",
          region: "REGION B (I)",
        },
        {
          municipio: "SAN JOSE DE OCOA",
          provincia: "SAN JOSE DE OCOA",
          region: "REGION B (I)",
        },
        {
          municipio: "RANCHO ARRIBA",
          provincia: "SAN JOSE DE OCOA",
          region: "REGION B (I)",
        },
        {
          municipio: "SABANA LARGA",
          provincia: "SAN JOSE DE OCOA",
          region: "REGION B (I)",
        },
        {
          municipio: "MOCA",
          provincia: "ESPALLIAT",
          region: "REGION C (II)",
        },
        {
          municipio: "CAYETANO GERMOSEN ",
          provincia: "ESPALLIAT",
          region: "REGION C (II)",
        },
        {
          municipio: "GASPAR HERNANDEZ",
          provincia: "ESPALLIAT",
          region: "REGION C (II)",
        },
        {
          municipio: "JAMAO AL NORTE ",
          provincia: "ESPALLIAT",
          region: "REGION C (II)",
        },
        {
          municipio: "SAN VICTOR",
          provincia: "ESPALLIAT",
          region: "REGION C (II)",
        },
        {
          municipio: "PUERTO PLATA ",
          provincia: "PUERTO PLATA",
          region: "REGION C (II)",
        },
        {
          municipio: "ALTAMIRA",
          provincia: "PUERTO PLATA",
          region: "REGION C (II)",
        },
        {
          municipio: "GUANANICO",
          provincia: "PUERTO PLATA",
          region: "REGION C (II)",
        },
        {
          municipio: "IMBERT",
          provincia: "PUERTO PLATA",
          region: "REGION C (II)",
        },
        {
          municipio: "LOS HIDALGOS ",
          provincia: "PUERTO PLATA",
          region: "REGION C (II)",
        },
        {
          municipio: "LUPERON ",
          provincia: "PUERTO PLATA",
          region: "REGION C (II)",
        },
        {
          municipio: "SOSUA ",
          provincia: "PUERTO PLATA",
          region: "REGION C (II)",
        },
        {
          municipio: "VILLA ISABELA",
          provincia: "PUERTO PLATA",
          region: "REGION C (II)",
        },
        {
          municipio: "VILLA MONTELLANO ",
          provincia: "PUERTO PLATA",
          region: "REGION C (II)",
        },
        {
          municipio: "SANTIAGO DE LOS CABALLEROS",
          provincia: "SANTIAGO",
          region: "REGION C (II)",
        },
        {
          municipio: "BAITOA",
          provincia: "SANTIAGO",
          region: "REGION C (II)",
        },
        {
          municipio: "HANICO",
          provincia: "SANTIAGO",
          region: "REGION C (II)",
        },
        {
          municipio: "LICEY AL MEDIO ",
          provincia: "SANTIAGO",
          region: "REGION C (II)",
        },
        {
          municipio: "PUNAL ",
          provincia: "SANTIAGO",
          region: "REGION C (II)",
        },
        {
          municipio: "SABAN IGLESIAS",
          provincia: "SANTIAGO",
          region: "REGION C (II)",
        },
        {
          municipio: "SAN JOSE DE LAS MATAS",
          provincia: "SANTIAGO",
          region: "REGION C (II)",
        },
        {
          municipio: "TAMBORIL ",
          provincia: "SANTIAGO",
          region: "REGION C (II)",
        },
        {
          municipio: "VILLA BISONO ",
          provincia: "SANTIAGO",
          region: "REGION C (II)",
        },
        {
          municipio: "VILLA GONZALES",
          provincia: "SANTIAGO",
          region: "REGION C (II)",
        },
        {
          municipio: "SAN FRANCISCO DE MACORIS",
          provincia: "DUARTE",
          region: "REGION D (III)",
        },
        {
          municipio: "ARENOSO ",
          provincia: "DUARTE",
          region: "REGION D (III)",
        },
        {
          municipio: "CASTILLO",
          provincia: "DUARTE",
          region: "REGION D (III)",
        },
        {
          municipio: "HOSTOS",
          provincia: "DUARTE",
          region: "REGION D (III)",
        },
        {
          municipio: "LAS GUARANAS",
          provincia: "DUARTE",
          region: "REGION D (III)",
        },
        {
          municipio: "PIMENTEL ",
          provincia: "DUARTE",
          region: "REGION D (III)",
        },
        {
          municipio: "VILLA ARRIBA",
          provincia: "DUARTE",
          region: "REGION D (III)",
        },
        {
          municipio: "SALCEDO",
          provincia: "HERMANAS MIRABAL",
          region: "REGION D (III)",
        },
        {
          municipio: "TENARES",
          provincia: "HERMANAS MIRABAL",
          region: "REGION D (III)",
        },
        {
          municipio: "VILLA TAPIA",
          provincia: "HERMANAS MIRABAL",
          region: "REGION D (III)",
        },
        {
          municipio: "NAGUA",
          provincia: "MARIA TRINIDAD SANCHEZ",
          region: "REGION D (III)",
        },
        {
          municipio: "CABRERA",
          provincia: "MARIA TRINIDAD SANCHEZ",
          region: "REGION D (III)",
        },
        {
          municipio: "EL FACTOR ",
          provincia: "MARIA TRINIDAD SANCHEZ",
          region: "REGION D (III)",
        },
        {
          municipio: "RIO SAN JUAN",
          provincia: "MARIA TRINIDAD SANCHEZ",
          region: "REGION D (III)",
        },
        {
          municipio: "SAMANA",
          provincia: "SAMANA ",
          region: "REGION D (III)",
        },
        {
          municipio: "LAS TERRENAS",
          provincia: "SAMANA ",
          region: "REGION D (III)",
        },
        {
          municipio: "SANCHEZ",
          provincia: "SAMANA ",
          region: "REGION D (III)",
        },
        {
          municipio: "NEIBA",
          provincia: "BAHORUCO",
          region: "REGION E (IV)",
        },
        {
          municipio: "GALVAN",
          provincia: "BAHORUCO",
          region: "REGION E (IV)",
        },
        {
          municipio: "LOS RIOS ",
          provincia: "BAHORUCO",
          region: "REGION E (IV)",
        },
        {
          municipio: "TAMAYO ",
          provincia: "BAHORUCO",
          region: "REGION E (IV)",
        },
        {
          municipio: "VILLA JARAGUA",
          provincia: "BAHORUCO",
          region: "REGION E (IV)",
        },
        {
          municipio: "BARAHONA",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "CABRAL ",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "EL PENON ",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "ENRIQUILLO",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "FUNDACION",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "JAQUIMEYES",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "LA CIENAGA",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "LAS SALINAS",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "PARAISO ",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "POLO ",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "VICENTE NOBLE",
          provincia: "BARAHONA",
          region: "REGION E (IV)",
        },
        {
          municipio: "COMENDADOR ",
          provincia: "ELIAS PINA ",
          region: "REGION E (IV)",
        },
        {
          municipio: "BANICA",
          provincia: "ELIAS PINA ",
          region: "REGION E (IV)",
        },
        {
          municipio: "EL LLANO ",
          provincia: "ELIAS PINA ",
          region: "REGION E (IV)",
        },
        {
          municipio: "HONDO VALLE",
          provincia: "ELIAS PINA ",
          region: "REGION E (IV)",
        },
        {
          municipio: "JUAN SANTIAGO ",
          provincia: "ELIAS PINA ",
          region: "REGION E (IV)",
        },
        {
          municipio: "PEDRO SANTANA",
          provincia: "ELIAS PINA ",
          region: "REGION E (IV)",
        },
        {
          municipio: "JIMANI",
          provincia: "INDEPENDENCIA",
          region: "REGION E (IV)",
        },
        {
          municipio: "CRISTOBAL",
          provincia: "INDEPENDENCIA",
          region: "REGION E (IV)",
        },
        {
          municipio: "DUVERGE",
          provincia: "INDEPENDENCIA",
          region: "REGION E (IV)",
        },
        {
          municipio: "LA DESCUBIERTA",
          provincia: "INDEPENDENCIA",
          region: "REGION E (IV)",
        },
        {
          municipio: "MELLA",
          provincia: "INDEPENDENCIA",
          region: "REGION E (IV)",
        },
        {
          municipio: "POSTRER RIO ",
          provincia: "INDEPENDENCIA",
          region: "REGION E (IV)",
        },
        {
          municipio: "SANTA CRUZ DE EL SEIBO ",
          provincia: "EL SEIBO ",
          region: "REGION F (V)",
        },
        {
          municipio: "MICHES",
          provincia: "EL SEIBO ",
          region: "REGION F (V)",
        },
        {
          municipio: "HATO MAYOR DEL REY",
          provincia: "HATO MAYOR ",
          region: "REGION F (V)",
        },
        {
          municipio: "EL VALLE",
          provincia: "HATO MAYOR ",
          region: "REGION F (V)",
        },
        {
          municipio: "SABANA DE LA MAR",
          provincia: "HATO MAYOR ",
          region: "REGION F (V)",
        },
        {
          municipio: "HIGUEY",
          provincia: "LA ALTAGRACIA",
          region: "REGION F (V)",
        },
        {
          municipio: "SAN RAFAEL DE YUMA",
          provincia: "LA ALTAGRACIA",
          region: "REGION F (V)",
        },
        {
          municipio: "LA ROMANA",
          provincia: "LA ROMANA ",
          region: "REGION F (V)",
        },
        {
          municipio: "GUAYMATE",
          provincia: "LA ROMANA ",
          region: "REGION F (V)",
        },
        {
          municipio: "VILLA HERMOSA",
          provincia: "LA ROMANA ",
          region: "REGION F (V)",
        },
        {
          municipio: "SAN PEDRO DE MACORIS",
          provincia: "SAN PEDRO DE MACORIS",
          region: "REGION F (V)",
        },
        {
          municipio: "CONSUELO ",
          provincia: "SAN PEDRO DE MACORIS",
          region: "REGION F (V)",
        },
        {
          municipio: "GUAYACANES",
          provincia: "SAN PEDRO DE MACORIS",
          region: "REGION F (V)",
        },
        {
          municipio: "QUISQUEYA",
          provincia: "SAN PEDRO DE MACORIS",
          region: "REGION F (V)",
        },
        {
          municipio: "RAMON SANTANA",
          provincia: "SAN PEDRO DE MACORIS",
          region: "REGION F (V)",
        },
        {
          municipio: "SAN JOSE DE LOS LLANOS",
          provincia: "SAN PEDRO DE MACORIS",
          region: "REGION F (V)",
        },
        {
          municipio: "AZUA DE COMPOSTELA ",
          provincia: "AZUA",
          region: "REGION G(VI)",
        },
        {
          municipio: "ESTEBANIA",
          provincia: "AZUA",
          region: "REGION G(VI)",
        },
        {
          municipio: "GUAYABAL",
          provincia: "AZUA",
          region: "REGION G(VI)",
        },
        {
          municipio: "LAS CHARCAS",
          provincia: "AZUA",
          region: "REGION G(VI)",
        },
        {
          municipio: "LAS YAYAS DE VIAJAMA",
          provincia: "AZUA",
          region: "REGION G(VI)",
        },
        {
          municipio: "PADRE DE LAS CASAS",
          provincia: "AZUA",
          region: "REGION G(VI)",
        },
        {
          municipio: "PERALTA",
          provincia: "AZUA",
          region: "REGION G(VI)",
        },
        {
          municipio: "PUEBLO VIEJO ",
          provincia: "AZUA",
          region: "REGION G(VI)",
        },
        {
          municipio: "SABANA YEGUA ",
          provincia: "AZUA",
          region: "REGION G(VI)",
        },
        {
          municipio: "TABARA ARRIBA",
          provincia: "AZUA",
          region: "REGION G(VI)",
        },
        {
          municipio: "SAN JUAN DE LA MAGUANA",
          provincia: "SAN JUAN ",
          region: "REGION G(VI)",
        },
        {
          municipio: "BOECHIO",
          provincia: "SAN JUAN ",
          region: "REGION G(VI)",
        },
        {
          municipio: "EL CERCADO ",
          provincia: "SAN JUAN ",
          region: "REGION G(VI)",
        },
        {
          municipio: "JUAN DE HERRERA",
          provincia: "SAN JUAN ",
          region: "REGION G(VI)",
        },
        {
          municipio: "LAS MATAS DE FARFAN ",
          provincia: "SAN JUAN ",
          region: "REGION G(VI)",
        },
        {
          municipio: "VALLEJUELO ",
          provincia: "SAN JUAN ",
          region: "REGION G(VI)",
        },
        {
          municipio: "PEDERNALES ",
          provincia: "PEDERNALES ",
          region: "REGION G(VI)",
        },
        {
          municipio: "OVIEDO ",
          provincia: "PEDERNALES ",
          region: "REGION G(VI)",
        },
        {
          municipio: "DAJABON",
          provincia: "DAJABON",
          region: "REGION H(VII)",
        },
        {
          municipio: "EL PINO ",
          provincia: "DAJABON",
          region: "REGION H(VII)",
        },
        {
          municipio: "LOMA DE CABRERA",
          provincia: "DAJABON",
          region: "REGION H(VII)",
        },
        {
          municipio: "PARTIDO",
          provincia: "DAJABON",
          region: "REGION H(VII)",
        },
        {
          municipio: "RESTAURACION ",
          provincia: "DAJABON",
          region: "REGION H(VII)",
        },
        {
          municipio: "MONTE CRISTI ",
          provincia: "MONTE CRISTI ",
          region: "REGION H(VII)",
        },
        {
          municipio: "CASTANUELA",
          provincia: "MONTE CRISTI ",
          region: "REGION H(VII)",
        },
        {
          municipio: "GUAYUBIN ",
          provincia: "MONTE CRISTI ",
          region: "REGION H(VII)",
        },
        {
          municipio: "LAS MATAS DE SANTA CRUZ",
          provincia: "MONTE CRISTI ",
          region: "REGION H(VII)",
        },
        {
          municipio: "PEPILLO SALCEDO ",
          provincia: "MONTE CRISTI ",
          region: "REGION H(VII)",
        },
        {
          municipio: "VILLA VASQUEZ",
          provincia: "MONTE CRISTI ",
          region: "REGION H(VII)",
        },
        {
          municipio: "SAN IGNACIO DE SABANETA",
          provincia: "SANTIAGO RODRIGUEZ",
          region: "REGION H(VII)",
        },
        {
          municipio: "MONCION",
          provincia: "SANTIAGO RODRIGUEZ",
          region: "REGION H(VII)",
        },
        {
          municipio: "VILLA LOS ALMACIGOS",
          provincia: "SANTIAGO RODRIGUEZ",
          region: "REGION H(VII)",
        },
        {
          municipio: "MAO",
          provincia: "VALVERDE",
          region: "REGION H(VII)",
        },
        {
          municipio: "ESPERANZA",
          provincia: "VALVERDE",
          region: "REGION H(VII)",
        },
        {
          municipio: "LAGUNA SALADA",
          provincia: "VALVERDE",
          region: "REGION H(VII)",
        },
        {
          municipio: "LA VEGA",
          provincia: "LA VEGA",
          region: "REGION I (VIII)",
        },
        {
          municipio: "CONSTANZA",
          provincia: "LA VEGA",
          region: "REGION I (VIII)",
        },
        {
          municipio: "JARABACOA",
          provincia: "LA VEGA",
          region: "REGION I (VIII)",
        },
        {
          municipio: "JIMA ABAJO ",
          provincia: "LA VEGA",
          region: "REGION I (VIII)",
        },
        {
          municipio: "BONAO",
          provincia: "MONSENOR NOUEL",
          region: "REGION I (VIII)",
        },
        {
          municipio: "MAIMON ",
          provincia: "MONSENOR NOUEL",
          region: "REGION I (VIII)",
        },
        {
          municipio: "PIEDRA BLANCA",
          provincia: "MONSENOR NOUEL",
          region: "REGION I (VIII)",
        },
        {
          municipio: "COTUI",
          provincia: "SANCHEZ RAMIREZ",
          region: "REGION I (VIII)",
        },
        {
          municipio: "CEVICOS",
          provincia: "SANCHEZ RAMIREZ",
          region: "REGION I (VIII)",
        },
        {
          municipio: "FANTINO",
          provincia: "SANCHEZ RAMIREZ",
          region: "REGION I (VIII)",
        },
        {
          municipio: "LA MATA",
          provincia: "SANCHEZ RAMIREZ",
          region: "REGION I (VIII)",
        },
      ],
      showModalMunicipios: false,
      search: "",
      seguros2: [],
      currentEdition: {} as any,
      campoFocus: "unap",
      mensageError: "Error",
      mensageExito: "Centro Registrado Exitosamente",
      mensageConfirm: "¿Está Seguro que desea Eliminar Este Centro?",
      encabezado: "",
      modoForm: "",
      showDatosPadre: false,
      showDatosMadre: false,
      showDatosTutor: false,
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      centro: {} as Centro,
      oneCentro: {} as Centro,
      one: {},
      nextNo: Number,
      centroSelected: [],
      clienteSelected: [],
      descripcionCliente: "",

      showInComentary: "",
      showModal: false,
      showModal7: false,
      showDelete: false,
      currentConsulta: "",
      estado: {} as object,
    };
  },

  updated() {
    if (this.centro.unap) {
      this.centro.unap = this.centro.unap.toUpperCase();
    }
  },

  async mounted() {
    if (this.$route.fullPath == "/centros/new") {
      this.modoForm = "add";
      this.encabezado = "Nuevo Centro";
    } else {
      this.modoForm = "show";
      this.encabezado = "Detalles de Centro";
    }

    if (this.modoForm == "add") {
      this.fillFields();
    } else {
      // this.showDeleteMethod();
      // if (typeof this.$route.params.id === "string") {
      //   await this.loadCentro(this.$route.params.id);
      // }
      this.centro = this.$store.state.user.currentCentro;
    }

    this.focus();
  },

  methods: {
    async createCentro(generateId: boolean) {
      await this.toggleLoading();
      if (generateId) {
        let no = this.$store.state.user.proxCodCentro + 1;
        let completivo = "900";
        let id_center = completivo + no;
        this.centro.id_center = id_center;
      }
      try {
        const res = await create(this.centro).then(
          async (res) => {
            if (res.data.Status == "Success") {
              this.$router.push("/centros");
            }
          },
          (err) => {
            console.log(err);
            this.toggleLoading();
          }
        );
      } catch (error) {
        console.error(error);
      }
      await this.toggleLoading();
    },

    defTelefono() {
      if (this.centro.telefono.length == 10) {
        let part1 = this.centro.telefono.substring(0, 3);
        let part2 = this.centro.telefono.substring(3, 6);
        let part3 = this.centro.telefono.substring(6, 10);
        let telefonoGuiones = part1 + "-" + part2 + "-" + part3;
        this.centro.telefono = telefonoGuiones;
      } else if (this.centro.telefono.length == 12) {
        let telefonoGuiones = this.centro.telefono;
        this.centro.telefono = telefonoGuiones;
      } else {
        alert("Teléfono Incorrecto");
        this.centro.telefono = "";
      }
    },

    setMunicipio(municipio: any) {
      this.centro.municipio = municipio.municipio;
      this.centro.provincia = municipio.provincia;
      this.centro.region = municipio.region;
      this.showModalMunicipioMethod();
      this.search = "";
      document.getElementById("telefono").focus();
      this.municipiosFiltrados = this.municipios;
    },

    showModalMunicipioMethod() {
      this.showModalMunicipios = !this.showModalMunicipios;
    },

    setSeguro(seguro: string) {
      this.centro.seguro = seguro;
      this.showModalMethod7();
      this.search = "";
      document.getElementById("ubicacion").focus();
    },

    buscar() {
      this.municipiosFiltrados = this.municipios.filter((municipio: any) => {
        return municipio.municipio
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },

    showModalMethod7() {
      this.seguros2 = this.seguros;
      this.showModal7 = !this.showModal7;
      // document.getElementById("oficio").focus();
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    deleteService(it: any) {
      if (confirm("Está Seguro que desea Eliminar Esta Consulta?")) {
        this.centro.consultas.splice(it, 1);
      }
    },

    editService(index: number, consulta: any) {
      this.currentEdition = consulta;
      this.showModalMethod();
    },

    newFormatDate(dateValue: Date) {
      moment.locale("es");
      return moment(dateValue).calendar();
    },

    async addActivity() {
      if (this.currentConsulta !== "") {
        this.centro.consultas.push({
          description: this.currentConsulta,
          date: new Date(),
        });
        this.currentConsulta = "";
      }
      document.getElementById("currentConsulta").focus();
    },

    calcularEdad(date: any) {
      let years = 0;
      let edad = Math.floor(
        moment(new Date()).diff(moment(date, "YYYY-MM-DD"), "years", true)
      );
      if (edad > 120 || edad < 0) {
        years = 0;
      } else {
        years = edad;
      }
      this.centro.edad = years;
    },

    fixTime() {
      this.centro.fecha = this.formatDateToFix(this.centro.fecha, true);
      this.centro.fechaActual = this.formatDateToFix(
        this.centro.fechaActual,
        true
      );
    },

    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DD");
      }
    },

    showDeleteMethod() {
      if (this.$store.state.user.type == "Power User") {
        this.showDelete = true;
      }
    },

    toggleShowDatosPadre() {
      this.showDatosPadre = !this.showDatosPadre;
    },
    toggleShowDatosMadre() {
      this.showDatosMadre = !this.showDatosMadre;
    },
    toggleShowDatosTutor() {
      this.showDatosTutor = !this.showDatosTutor;
    },

    isError(message: string) {
      if (message == this.mensageExito) {
        return "success";
      } else {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcCentro() {
      (this.centro.retension = this.centro.bruto * 0.1),
        (this.centro.neto = this.centro.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fillFields() {
      this.centro.status = "A";
    },

    cleanFields() {
      this.centro.id_center = "";
      this.centro.municipio = "";
      this.centro.provincia = "";
      this.centro.region = "";
      this.centro.status = "";
      this.centro.telefono = "";
      this.centro.unap = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById(this.campoFocus).focus();
    },
    focus2() {
      document.getElementById(this.campoFocus2).focus();
    },
  },
});
