import axios, { AxiosInstance } from "axios";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: "https://zul1uz0bog.execute-api.us-east-1.amazonaws.com/dev",
  headers: {
    "Content-Type": "application/json",
    "x-api-key": "mNOCFuqUsP9tVQlbEoUhx2KG8Wma2ROh2pGd2LDI",
  },
});
export default axiosInstance;
