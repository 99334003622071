
import { loginUsuario } from "@/services/usuario/UsuarioService";
import { getByUsername } from "@/services/gcorporativo/Gcorporativo";
import { getAll } from "@/services/centro/Centro";
import { Usuario } from "@/interfaces/Usuario";
export default {
  name: "LoginView",
  components: {},

  data() {
    return {
      passwordFieldType: "password",
      flexCheckDefault: false,
      showModal: true,
      cargando: false,
      error: "",
      usuario: {} as Usuario,
    };
  },

  // mounted() {
  //   this.loadCentros();
  // },

  methods: {
    async loadGcorporativo(username: string) {
      this.toggleLoading();
      try {
        const res = await getByUsername(username);
        // this.$store.state.user.gcorp = res.data.response.Items[0].id_gcorporativo;
        // if (res.data.response.Count == 0) {
        //   this.fillFields();
        // } else {
        //   this.gcorporativo = res.data.response.Items[0];
        // }
      } catch (error) {
        console.error(error);
      }
      this.toggleLoading();
    },

    async loadCentros() {
      try {
        const res = await getAll();
        this.$store.state.user.centros = res.data.centros;
        this.$store.state.user.filteredCentros = res.data.centros;
      } catch (error) {
        console.error(error);
      }
    },

    togglePasswordFieldType() {
      if (this.flexCheckDefault == false) {
        this.passwordFieldType = "password";
      } else {
        this.passwordFieldType = "text";
      }
    },
    calcPctoGrasa() {
      let input = {
        bicep: 4.0,
        abd: 5.0,
        sub: 7.0,
      };

      let result = 12.4;

      let response = {
        input: input,
        result: result,
      };

      return response;
    },

    getValue(originalValue: number) {
      let result;
      let rangoVertical = 20;
      let relacionCambiaria = originalValue / rangoVertical;
      let valorVertical = 50;

      let sustraendo = relacionCambiaria * valorVertical;
      let totalVertical = 300;
      result = totalVertical - sustraendo;
      return result;
    },

    calcPolyline_points(isAll: boolean) {
      let couple;
      let points: string[] = [];
      if (isAll) {
        points = ["100,300"];
      } else {
        points = [];
      }

      let scale = 100;
      for (let i = 0; i < this.$store.state.user.defaultStatus.length; i++) {
        couple =
          scale +
          "," +
          this.getValue(this.$store.state.user.defaultStatus[i].peso);
        this.$store.state.user.valores.push(
          this.getValue(this.$store.state.user.defaultStatus[i].peso)
        );
        this.$store.state.user.valores2.push(scale);
        points.push(couple);
        scale = scale + 50;
      }
      if (isAll) {
        points.push(`${scale - 50},300`);
      }

      let str = "";

      for (let i = 0; i < points.length; i++) {
        str = str + points[i] + " ";
      }

      return str;
    },

    calcPolyline_pointsCintura(isAll: boolean) {
      let couple;
      let points: string[] = [];
      if (isAll) {
        points = ["100,300"];
      } else {
        points = [];
      }

      let scale = 100;
      for (let i = 0; i < this.$store.state.user.defaultStatus.length; i++) {
        couple =
          scale +
          "," +
          this.getValue(this.$store.state.user.defaultStatus[i].cintura);
        this.$store.state.user.valoresCintura.push(
          this.getValue(this.$store.state.user.defaultStatus[i].cintura)
        );
        this.$store.state.user.valoresCintura2.push(scale);
        points.push(couple);
        scale = scale + 50;
      }
      if (isAll) {
        points.push(`${scale - 50},300`);
      }

      let str = "";

      for (let i = 0; i < points.length; i++) {
        str = str + points[i] + " ";
      }

      return str;
    },

    calcPolyline_pointsAbdomen(isAll: boolean) {
      let couple;
      let points: string[] = [];
      if (isAll) {
        points = ["100,300"];
      } else {
        points = [];
      }

      let scale = 100;
      for (let i = 0; i < this.$store.state.user.defaultStatus.length; i++) {
        couple =
          scale +
          "," +
          this.getValue(this.$store.state.user.defaultStatus[i].abdomen);
        this.$store.state.user.valoresAbdomen.push(
          this.getValue(this.$store.state.user.defaultStatus[i].abdomen)
        );
        this.$store.state.user.valoresAbdomen2.push(scale);
        points.push(couple);
        scale = scale + 50;
      }
      if (isAll) {
        points.push(`${scale - 50},300`);
      }

      let str = "";

      for (let i = 0; i < points.length; i++) {
        str = str + points[i] + " ";
      }

      return str;
    },

    calcPolyline_pointsCadera(isAll: boolean) {
      let couple;
      let points: string[] = [];
      if (isAll) {
        points = ["100,300"];
      } else {
        points = [];
      }

      let scale = 100;
      for (let i = 0; i < this.$store.state.user.defaultStatus.length; i++) {
        couple =
          scale +
          "," +
          this.getValue(this.$store.state.user.defaultStatus[i].cadera);
        this.$store.state.user.valoresCadera.push(
          this.getValue(this.$store.state.user.defaultStatus[i].cadera)
        );
        this.$store.state.user.valoresCadera2.push(scale);
        points.push(couple);
        scale = scale + 50;
      }
      if (isAll) {
        points.push(`${scale - 50},300`);
      }

      let str = "";

      for (let i = 0; i < points.length; i++) {
        str = str + points[i] + " ";
      }

      return str;
    },

    calcPolyline_pointsImc(isAll: boolean) {
      let couple;
      let points: string[] = [];
      if (isAll) {
        points = ["100,300"];
      } else {
        points = [];
      }

      let scale = 100;
      for (let i = 0; i < this.$store.state.user.defaultStatus.length; i++) {
        couple =
          scale +
          "," +
          this.getValue(this.$store.state.user.defaultStatus[i].imc);
        this.$store.state.user.valoresImc.push(
          this.getValue(this.$store.state.user.defaultStatus[i].imc)
        );
        this.$store.state.user.valoresImc2.push(scale);
        points.push(couple);
        scale = scale + 50;
      }
      if (isAll) {
        points.push(`${scale - 50},300`);
      }

      let str = "";

      for (let i = 0; i < points.length; i++) {
        str = str + points[i] + " ";
      }

      return str;
    },
    calcPolyline_pointsFuerza(isAll: boolean) {
      let couple;
      let points: string[] = [];
      if (isAll) {
        points = ["100,300"];
      } else {
        points = [];
      }

      let scale = 100;
      for (let i = 0; i < this.$store.state.user.defaultStatus.length; i++) {
        couple =
          scale +
          "," +
          this.getValue(this.$store.state.user.defaultStatus[i].fuerza);
        this.$store.state.user.valoresFuerza.push(
          this.getValue(this.$store.state.user.defaultStatus[i].fuerza)
        );
        this.$store.state.user.valoresFuerza2.push(scale);
        points.push(couple);
        scale = scale + 50;
      }
      if (isAll) {
        points.push(`${scale - 50},300`);
      }

      let str = "";

      for (let i = 0; i < points.length; i++) {
        str = str + points[i] + " ";
      }

      return str;
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    arreglar() {
      this.usuario.email = this.usuario.email.toLowerCase().trim();
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    // login() {
    //   this.$store.dispatch("loginAction");
    //   this.$store.state.user.type = this.role;
    //   this.$store.state.user.usuario = this.usuario;
    //
    // },

    async login() {
      this.toggleLoading();
      await loginUsuario(this.usuario).then(
        (res) => {
          if (res.status === 200) {
            // localStorage.setItem("token", res.data.token);
            this.$store.dispatch("loginAction");
            this.$store.state.user.entities = res.data.user.entities;
            this.$store.state.user.type = res.data.user.role;
            this.$store.state.user.usuario = res.data.user.name;
            this.$store.state.user.username = res.data.user.username;
            this.$store.state.user.email = res.data.user.email;
            this.$store.state.user.currentUsuario = res.data.user;
            if (this.$store.state.user.entities.includes("GRUPO CORPORATIVO")) {
              this.loadGcorporativo(this.$store.state.user.username);
            }
            this.$store.state.user.profileImg = `https://isys-assets-bucket.s3.amazonaws.com/usuarios/${this.$store.state.user.username}.jpg`;

            this.$router.push("/dashboard");
          }
        },
        (err) => {
          if (err.response.status === 403) {
            this.error = err.response.data.message;
          } else if (err.response.status === 401) {
            this.error = err.response.data.message;
          } else {
            this.error =
              "Sorry.... the backend server is down!! please try again later";
          }
        }
      );
      this.toggleLoading();
    },
  },
};
