import { Empleador } from "@/interfaces/Empleador";
import { AxiosResponse } from "axios";
import axios from "./axios";

export const create = async (empleador: Empleador): Promise<AxiosResponse> =>
  await axios.put("/create", empleador);

export const getId = async (): Promise<AxiosResponse> =>
  await axios.get("/getid");

export const getAll = async (): Promise<AxiosResponse> =>
  await axios.get("/getall");

export const getByUsername = async (username: string): Promise<AxiosResponse> =>
  await axios.get(`/getbyusername?username=${username}`);
