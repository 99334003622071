
import { defineComponent } from "vue";
import { Centro } from "@/interfaces/Centro";
// import {
//   deleteCentro,
//   getCentro,
//   updateCentro,
// } from "@/services/centro/Centro";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "centro-list",
  data() {
    return {
      cargando: false,
      centro: {} as Centro,
    };
  },
  methods: {
    toPrint() {
      window.print();
    },
    toggleLoading() {
      this.cargando = !this.cargando;
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },
    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("D/MM/yyyy HH:mm");
    },

    formatDate2(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("D/MM/yyyy");
    },

    // async loadCentro(id: string) {
    //   this.toggleLoading();
    //   try {
    //     const { data } = await getCentro(id);
    //     this.centro = data;
    //   } catch (error) {
    //     //console.error(error);
    //   }
    //   await this.toggleLoading();
    //   this.toPrint();

    //   if (this.$store.state.ids.length == 0) {
    //     this.$router.push(`/centros/${this.centro._id}`);
    //   } else {
    //     this.$router.push(`/centroslot/new`);
    //   }

    //   // this.$router.push(`/centros/${this.centro._id}`);
    // },
    // async handleUpdate() {
    //   try {
    //     if (typeof this.$route.params.id === "string") {
    //       await updateCentro(this.$route.params.id, this.centro);
    //       this.$router.push("/centros");
    //     }
    //   } catch (error) {
    //     //console.error(error);
    //   }
    // },
    // async handleDelete() {
    //   try {
    //     if (typeof this.$route.params.id === "string") {
    //       deleteCentro(this.$route.params.id);
    //       this.$router.push("/centros");
    //     }
    //   } catch (error) {
    //     //console.error(error);
    //   }
    // },
  },
  mounted() {
    if (typeof this.$route.params.id === "string") {
      this.loadCentro(this.$route.params.id);
    }
  },

  // beforeUnmount() {
  //   this.toPrint();
  // }
});
