import axios, { AxiosInstance } from "axios";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: "https://1dtl3hsj78.execute-api.us-east-1.amazonaws.com/dev",
  headers: {
    "Content-Type": "application/json",
    "x-api-key": "GKR16J9c1n4KEDPLEecxv3NoH4ouPwXW6dFe1ac0",
  },
});
export default axiosInstance;
