
import Navbar from "@/components/Navbar.vue";
import {
  getS3Url_cedulafecha,
  getS3Url_cedulafechaEval,
} from "@/services/s3/s3Service";
import { defineComponent } from "vue";
import { Evaluacion } from "@/interfaces/Evaluacion";
import { create } from "@/services/evaluacion/Evaluacion";
import { getEvaluacions } from "@/services/elderstatistic/Evaluacion";
import { destroy } from "@/services/evaluacion/Evaluacion";
import {
  getPatient,
  getPatientFechaNacimiento,
} from "@/services/elderstatistic/UsuarioService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "evaluacions-form",
  components: {
    Navbar,
  },
  data() {
    return {
      showModalConfirm: false,
      showSpinner: false,
      lockCentro: false,
      filteredCentros: [],
      centros: [
        {
          unap: "MATA NARANJO",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
          id_center: "303",
        },
        {
          unap: "IGLESIA VIDA EN CRISTO",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "JUNTA DE VECINOS SAN ANTONIO",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "NANCY ORTIZ",
          region: "REGIÓN I",
          provincia: "PERAVIA",
          telefono: "-",
        },
        {
          unap: "ARROYO HONDO",
          region: "REGIÓN V",
          provincia: "LA ROMANA",
          telefono: "-",
        },
        {
          unap: "CENTRO FUNDACION DE DIOS",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "HATO VIEJO",
          region: "REGIÓN MONTE PLATA",
          provincia: "MONTE PLATA",
          telefono: "-",
        },
        {
          unap: "CENTRO DIAGNOSTICO HATO MAYOR",
          region: "REGIÓN V",
          provincia: "HATO MAYOR",
          telefono: "-",
        },
        {
          unap: "CERCA",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "LA CIENAGA",
          region: "REGIÓN I",
          provincia: "SAN JOSE DE OCOA",
          telefono: "-",
        },
        {
          unap: "LA PALMILLA",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "VOLUNTARIADO NACIONAL DE RESCATE Y PREVENCIÓN",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "FUNDACION MI SUEÑO",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "GERONIMO PINALES",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "LA CIENAGA",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "EL CAIMITO",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "LOS RANCHITOS",
          region: "REGIÓN I",
          provincia: "SAN JOSE DE OCOA",
          telefono: "-",
        },
        {
          unap: "LA GUAZARA",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "LA CATALINA",
          region: "REGIÓN I",
          provincia: "PERAVIA",
          telefono: "-",
        },
        {
          unap: "BARRIO DUARTE",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "DON JUAN",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "CABRETO NARANJO",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "FUNDACIÓN DR. RAMON DURAN",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "CENTRO DE SALUD INTEGRAL DR. SOCRATES MAÑON",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "FUNDACION PABLITO",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "RALMA",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "JAIBON",
          region: "REGIÓN VII",
          provincia: "VALVERDE",
          telefono: "-",
        },
        {
          unap: "LOS FRAILES III",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "SAN BARTOLO",
          region: "REGIÓN VIII",
          provincia: "LA VEGA",
          telefono: "-",
        },
        {
          unap: "LA JOYA",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "GUERRA",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "EL CAOBAL",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "MATA GORDA",
          region: "REGIÓN I",
          provincia: "PERAVIA",
          telefono: "-",
        },
        {
          unap: "FUNDACION LEVANTANDO LOS CAIDOS",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "PUEBLO NUEVO",
          region: "REGIÓN MONTE PLATA",
          provincia: "MONTE PLATA",
          telefono: "-",
        },
        {
          unap: "CLINICA RURAL LAS CHARCAS",
          region: "REGIÓN VI",
          provincia: "AZUA",
          telefono: "-",
        },
        {
          unap: "FUNBIDE",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "FUNDACION",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "MELLA",
          region: "REGIÓN IV",
          provincia: "INDEPENDENCIA",
          telefono: "-",
        },
        {
          unap: "LOS COROZOS",
          region: "REGIÓN VIII",
          provincia: "SANCHEZ RAMIREZ",
          telefono: "-",
        },
        {
          unap: "MAMA TINGO",
          region: "REGIÓN V",
          provincia: "LA ALTAGRACIA",
          telefono: "-",
        },
        {
          unap: "EL HIGUERO",
          region: "REGIÓN VIII",
          provincia: "LA VEGA",
          telefono: "-",
        },
        {
          unap: "LA ESPERANZA",
          region: "REGIÓN VII",
          provincia: "MONTE CRISTI",
          telefono: "-",
        },
        {
          unap: "LAS MERCEDES",
          region: "REGIÓN IV",
          provincia: "INDEPENDENCIA",
          telefono: "-",
        },
        {
          unap: "SAN FRANCISCO",
          region: "REGIÓN VI",
          provincia: "AZUA",
          telefono: "-",
        },
        {
          unap: "JUAN LOPEZ",
          region: "REGIÓN II",
          provincia: "ESPAILLAT",
          telefono: "-",
        },
        {
          unap: "BUEN PASTOR",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "TIERRA DURA",
          region: "REGIÓN II",
          provincia: "ESPAILLAT",
          telefono: "-",
        },
        {
          unap: "MAMA TINGO",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "MARGARIN",
          region: "REGIÓN V",
          provincia: "EL SEIBO",
          telefono: "-",
        },
        {
          unap: "EL LIMONAL",
          region: "REGIÓN I",
          provincia: "PERAVIA",
          telefono: "-",
        },
        {
          unap: "VILLA ESPERANZA",
          region: "REGIÓN VI",
          provincia: "SAN JUAN DE LA MAGUANA",
          telefono: "-",
        },
        {
          unap: "JUNTA DE VECINO ADRIAN MARTINEZ",
          region: "REGIÓN I",
          provincia: "PERAVIA",
          telefono: "-",
        },
        {
          unap: "EL PINO",
          region: "REGIÓN VIII",
          provincia: "LA VEGA",
          telefono: "-",
        },
        {
          unap: "GREGORIO LUPERON",
          region: "REGIÓN II",
          provincia: "PUERTO PLATA",
          telefono: "-",
        },
        {
          unap: "MAMA TINGO",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "CENTRO COMUNITARIO LOS RAMIREZ",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "SAN ANTONIO",
          region: "REGIÓN MONTE PLATA",
          provincia: "MONTE PLATA",
          telefono: "-",
        },
        {
          unap: "VILLA LIBERACION",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "LOS COCOS",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "FUNDAZURZA",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "LA CIENAGA",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "LA CEIBA DE MADERA",
          region: "REGIÓN II",
          provincia: "ESPAILLAT",
          telefono: "-",
        },
        {
          unap: "CAMPO LINDO II",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "CABRETO",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "SANTA ROSA",
          region: "REGIÓN II",
          provincia: "ESPAILLAT",
          telefono: "-",
        },
        {
          unap: "30 DE MAYO",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "HATO NUEVO",
          region: "REGIÓN VI",
          provincia: "AZUA",
          telefono: "-",
        },
        {
          unap: "FUNDACION YAGUATE",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "FUNDACION FANTINO",
          region: "REGIÓN III",
          provincia: "MARÍA TRINIDAD SANCHEZ",
          telefono: "-",
        },
        {
          unap: "LOS GUANDULES I",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "FUNDACION LOS TRES BRAZOS (DOMICILIARIOS)",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "FUNDACION",
          region: "REGIÓN V",
          provincia: "LA ROMANA",
          telefono: "-",
        },
        {
          unap: "FUNDACIÓN SOL VIVIENTE",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "FUNDACION SIEMBRA Y COSECHA  RD",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "LAS FLORES",
          region: "REGIÓN I",
          provincia: "SAN JOSE DE OCOA",
          telefono: "-",
        },
        {
          unap: "MENDOZA",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "YACOT (DOMICILIARIO)",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "FUNDACION LOS GARCIA (DOMICILIARIO)",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "FUNDACION VALIENTE",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "FUNRASO",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "MINISTERIO JESUS DE NAZARET",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "FUNDACION PROAYUDA ENVEJECIENTES",
          region: "REGIÓN I",
          provincia: "PERAVIA",
          telefono: "-",
        },
        {
          unap: "CAPOTILLO CDC",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "MANOGUAYABO",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "MARIANO CESTERO",
          region: "REGIÓN VII",
          provincia: "DAJABON",
          telefono: "-",
        },
        {
          unap: "PONTON",
          region: "REGIÓN VIII",
          provincia: "LA VEGA",
          telefono: "-",
        },
        {
          unap: "VALENTIN SALINERO",
          region: "REGIÓN VII",
          provincia: "DAJABON",
          telefono: "-",
        },
        {
          unap: "ENRIQUILLO",
          region: "REGIÓN VII",
          provincia: "VALVERDE",
          telefono: "-",
        },
        {
          unap: "EL PINAR",
          region: "REGIÓN I",
          provincia: "SAN JOSE DE OCOA",
          telefono: "-",
        },
        {
          unap: "GUAYABAL",
          region: "REGIÓN VI",
          provincia: "AZUA",
          telefono: "-",
        },
        {
          unap: "ASOC. DE LIDERES EMPRENDEDORES VOLUNTARIOS",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "LA CURVA",
          region: "REGIÓN VII",
          provincia: "DAJABON",
          telefono: "-",
        },
        {
          unap: "CRISTOBAL",
          region: "REGIÓN IV",
          provincia: "INDEPENDENCIA",
          telefono: "-",
        },
        {
          unap: "EL LICEY",
          region: "REGIÓN II",
          provincia: "LA VEGA",
          telefono: "-",
        },
        {
          unap: "PEDRO BRAND CENTRO DIAGNOSTICO",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "ESMERALDO DIAZ",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "JAQUIMEYES",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "LA ATALAYA",
          region: "REGIÓN VIII",
          provincia: "SANCHEZ RAMIREZ",
          telefono: "-",
        },
        {
          unap: "ASCENCION DEL SEÑOR",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "LA LUISA",
          region: "REGIÓN MONTE PLATA",
          provincia: "MONTE PLATA",
          telefono: "-",
        },
        {
          unap: "VILLA ORTEGA",
          region: "REGIÓN V",
          provincia: "HATO MAYOR",
          telefono: "-",
        },
        {
          unap: "JOBA ARRIBA",
          region: "REGIÓN II",
          provincia: "ESPAILLAT",
          telefono: "-",
        },
        {
          unap: "PUNTA DE GARZA",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "LA AGUSTINA",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "TOMAS DESIR LEBRON",
          region: "REGIÓN V",
          provincia: "LA ROMANA",
          telefono: "-",
        },
        {
          unap: "GOUTIER",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "HOSPITAL NUESTRA SEÑORA DEL CARMEN",
          region: "REGIÓN VI",
          provincia: "AZUA",
          telefono: "-",
        },
        {
          unap: "DIOS HABLA",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "EL RUBIO",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "PADRE CAVALOTTO",
          region: "REGIÓN V",
          provincia: "LA ROMANA",
          telefono: "-",
        },
        {
          unap: "CANOA",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "PLAYA BARAHONA",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "HOSPITAL SAGRADO CORAZON DE JESUS",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "LA NUEVA BARQUITA",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "VILLA BAO",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "CAMPO LINDO I",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "ISABELITA",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "QUITA SUEÑO",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "RANCHO VIEJO",
          region: "REGIÓN VIII",
          provincia: "LA VEGA",
          telefono: "-",
        },
        {
          unap: "MIRADOR SUR",
          region: "REGIÓN V",
          provincia: "EL SEIBO",
          telefono: "-",
        },
        {
          unap: "SAN ANTONIO",
          region: "REGIÓN I",
          provincia: "SAN JOSE DE OCOA",
          telefono: "-",
        },
        {
          unap: "PUEBLO ABAJO",
          region: "REGIÓN I",
          provincia: "SAN JOSE DE OCOA",
          telefono: "-",
        },
        {
          unap: "EL PUEBLECITO",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "LAS CHINAS",
          region: "REGIÓN V",
          provincia: "HATO MAYOR",
          telefono: "-",
        },
        {
          unap: "BONAGUA",
          region: "REGIÓN II",
          provincia: "ESPAILLAT",
          telefono: "-",
        },
        {
          unap: "EL HIGUERO",
          region: "REGIÓN V",
          provincia: "LA ROMANA",
          telefono: "-",
        },
        {
          unap: "EL DIQUE",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "FUNDACION VILLA FARO",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "VALENTIN BUTTEN",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "ENTRADA DE MAO",
          region: "REGIÓN VII",
          provincia: "VALVERDE",
          telefono: "-",
        },
        {
          unap: "VILLA HERMOSA I & II",
          region: "REGIÓN V",
          provincia: "LA ROMANA",
          telefono: "-",
        },
        {
          unap: "FUNDACION 2 ENERO",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "FUNDACION BARRIO ANGELES",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "CORBANO NORTE",
          region: "REGIÓN VI",
          provincia: "SAN JUAN DE LA MAGUANA",
          telefono: "-",
        },
        {
          unap: "CLINICA RURAL LOS  YAGUARIZOS",
          region: "REGIÓN I",
          provincia: "PERAVIA",
          telefono: "-",
        },
        {
          unap: "LIMONES DEL FACTOR",
          region: "REGIÓN III",
          provincia: "MARÍA TRINIDAD SANCHEZ",
          telefono: "-",
        },
        {
          unap: "EL DIAZ PINEYRO",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "CABALLONA",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "LA FURNIA",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "FUNDACION VILLA MORADA",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "ANGELINA",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "COLONIA MIXTA",
          region: "REGIÓN IV",
          provincia: "INDEPENDENCIA",
          telefono: "-",
        },
        {
          unap: "ENCAMADOS (DOMICILIARIOS)",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "VIRGILIO DE LA NUEZ",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "BELLA VISTA",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "LOMA DE CASTAÑUELAS",
          region: "REGIÓN VII",
          provincia: "MONTE CRISTI",
          telefono: "-",
        },
        {
          unap: "LAS YAGUITAS",
          region: "REGIÓN V",
          provincia: "HATO MAYOR",
          telefono: "-",
        },
        {
          unap: "EL SALADO",
          region: "REGIÓN V",
          provincia: "LA ALTAGRACIA",
          telefono: "-",
        },
        {
          unap: "LOS PLATANITOS",
          region: "REGIÓN V",
          provincia: "LA ALTAGRACIA",
          telefono: "-",
        },
        {
          unap: "HOYO DE JAYA",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "LOTES Y SERVICIOS",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "FUNDACION DOMINICANA DE DESARROLLO SOSTENIBLE PROHUMANO",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "VILLA CARMELA",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "MATANZAS",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "VILLA HERMOSA III",
          region: "REGIÓN V",
          provincia: "LA ROMANA",
          telefono: "-",
        },
        {
          unap: "EL JAVILLAR",
          region: "REGIÓN II",
          provincia: "PUERTO PLATA",
          telefono: "-",
        },
        {
          unap: "LA MAJAGUA",
          region: "REGIÓN III",
          provincia: "SAMANÁ",
          telefono: "-",
        },
        {
          unap: "CAMPAMENTO 16 DE AGOSTO",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "JUAN SANCHEZ",
          region: "REGIÓN MONTE PLATA",
          provincia: "MONTE PLATA",
          telefono: "-",
        },
        {
          unap: "VILLA NAVARRO",
          region: "REGIÓN V",
          provincia: "HATO MAYOR",
          telefono: "-",
        },
        {
          unap: "MORQUECHO",
          region: "REGIÓN V",
          provincia: "HATO MAYOR",
          telefono: "-",
        },
        {
          unap: "MATA HAMBRE",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "DE LAS MANOS DE JESUS",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "LAS CAOBAS",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "JUNTA DE DESARROLLO DE GUALEY",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "POLO",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "HATO DEL PADRE",
          region: "REGIÓN VI",
          provincia: "SAN JUAN DE LA MAGUANA",
          telefono: "-",
        },
        {
          unap: "YERBA BUENA",
          region: "REGIÓN V",
          provincia: "HATO MAYOR",
          telefono: "-",
        },
        {
          unap: "LA RAQUETA",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "BOCA CHICA II",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "LOS LIMONES DE VIILA TAPIA",
          region: "REGIÓN III",
          provincia: "HERMANAS MIRABAL",
          telefono: "-",
        },
        {
          unap: "LOS TOCONES",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "LAS CHARCAS DE GARABITO",
          region: "REGIÓN VI",
          provincia: "SAN JUAN DE LA MAGUANA",
          telefono: "-",
        },
        {
          unap: "LA REFORMA",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "HATO SAN PEDRO",
          region: "REGIÓN MONTE PLATA",
          provincia: "MONTE PLATA",
          telefono: "-",
        },
        {
          unap: "SAN MARTIN DE PORRES",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "PALO BLANCO",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "MUNICIPAL",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "LUIS J SUAREZ",
          region: "REGIÓN V",
          provincia: "LA ROMANA",
          telefono: "-",
        },
        {
          unap: "ESTEBANIA BORINQUE",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "LLANO DE PEREZ",
          region: "REGIÓN II",
          provincia: "PUERTO PLATA",
          telefono: "-",
        },
        {
          unap: "LAS LAGUNAS",
          region: "REGIÓN II",
          provincia: "ESPAILLAT",
          telefono: "-",
        },
        {
          unap: "JUNTA DE VECINOS PERALEJOS CAMBIA",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "ESCALERETA",
          region: "REGIÓN II",
          provincia: "PUERTO PLATA",
          telefono: "-",
        },
        {
          unap: "HOSPITAL MUNICIPAL YAMASA",
          region: "REGIÓN MONTE PLATA",
          provincia: "MONTE PLATA",
          telefono: "-",
        },
        {
          unap: "SAN ISIDRO",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "MERCEDITO VIZCAINO",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "DIVINA PROVIDENCIA",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "FELIZ GARCIA",
          region: "REGIÓN II",
          provincia: "ESPAILLAT",
          telefono: "-",
        },
        {
          unap: "VILLA SOMBRERO",
          region: "REGIÓN I",
          provincia: "PERAVIA",
          telefono: "-",
        },
        {
          unap: "CAYACOA",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "VILLA LIBERACION",
          region: "REGIÓN II",
          provincia: "PUERTO PLATA",
          telefono: "-",
        },
        {
          unap: "PERPETUO SOCORRO",
          region: "REGIÓN VI",
          provincia: "SAN JUAN DE LA MAGUANA",
          telefono: "-",
        },
        {
          unap: "PADRE DE LAS CASAS",
          region: "REGIÓN II",
          provincia: "PUERTO PLATA",
          telefono: "-",
        },
        {
          unap: "JACAGUA ARRIBA",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "SAN BARTOLO",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "LOS YAYALES",
          region: "REGIÓN III",
          provincia: "MARÍA TRINIDAD SANCHEZ",
          telefono: "-",
        },
        {
          unap: "MADRE LAURA",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "BOCA FERREA",
          region: "REGIÓN II",
          provincia: "ESPAILLAT",
          telefono: "-",
        },
        {
          unap: "LOS ARROCES",
          region: "REGIÓN VIII",
          provincia: "MONSEÑOR NOUEL",
          telefono: "-",
        },
        {
          unap: "MANUEL RODRIGUEZ",
          region: "REGIÓN II",
          provincia: "ESPAILLAT",
          telefono: "-",
        },
        {
          unap: "KM 10",
          region: "REGIÓN V",
          provincia: "LA ROMANA",
          telefono: "-",
        },
        {
          unap: "VILLA DUARTE",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "CORALES CAÑA",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "SANTA CLARA",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "BARRIO LINDO",
          region: "REGIÓN VIII",
          provincia: "SANCHEZ RAMIREZ",
          telefono: "-",
        },
        {
          unap: "BASIMA",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "MONTE ADENTRO",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "EL SILENCIO",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "YOLANDA GUZMAN",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "LOS COROZOS",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "LOS ALCARRIZOS I",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "SIERRA PRIETA",
          region: "REGIÓN VIII",
          provincia: "SANCHEZ RAMIREZ",
          telefono: "-",
        },
        {
          unap: "CABALLERO",
          region: "REGIÓN VIII",
          provincia: "SANCHEZ RAMIREZ",
          telefono: "-",
        },
        {
          unap: "LOS COCOS",
          region: "REGIÓN IV",
          provincia: "BAHORUCO",
          telefono: "-",
        },
        {
          unap: "LAS PALOMAS",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "PILOTO",
          region: "REGIÓN VII",
          provincia: "MONTE CRISTI",
          telefono: "-",
        },
        {
          unap: "EL PEÑON",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "LAS 7S",
          region: "REGIÓN VIII",
          provincia: "LA VEGA",
          telefono: "-",
        },
        {
          unap: "CORBANO SUR 2",
          region: "REGIÓN VI",
          provincia: "SAN JUAN DE LA MAGUANA",
          telefono: "-",
        },
        {
          unap: "EL PUERTO",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "FUNDACOSI",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "MAIMON",
          region: "REGIÓN II",
          provincia: "PUERTO PLATA",
          telefono: "-",
        },
        {
          unap: "PARAISO",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "PINA VIEJA",
          region: "REGIÓN VIII",
          provincia: "SANCHEZ RAMIREZ",
          telefono: "-",
        },
        {
          unap: "URBANO VILLA TAPIA",
          region: "REGIÓN III",
          provincia: "HERMANAS MIRABAL",
          telefono: "-",
        },
        {
          unap: "INGENIO OZAMA",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "ANA CRUZ",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "RIO GRANDE",
          region: "REGIÓN II",
          provincia: "PUERTO PLATA",
          telefono: "-",
        },
        {
          unap: "SANTA BARBARA",
          region: "REGIÓN VII",
          provincia: "MONTE CRISTI",
          telefono: "-",
        },
        {
          unap: "PALOMA MENSAJERA",
          region: "REGIÓN IV",
          provincia: "BAHORUCO",
          telefono: "-",
        },
        {
          unap: "MATANCITAS",
          region: "REGIÓN III",
          provincia: "MARÍA TRINIDAD SANCHEZ",
          telefono: "-",
        },
        {
          unap: "FUNDACION PERAVIA",
          region: "REGIÓN I",
          provincia: "PERAVIA",
          telefono: "-",
        },
        {
          unap: "SABANA TORO",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "MONTAÑO",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "VENDAÑO",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "DOÑA ANA",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "CABARETE",
          region: "REGIÓN II",
          provincia: "PUERTO PLATA",
          telefono: "-",
        },
        {
          unap: "LOS LIMONES DE PIMENTEL",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "VILLA LIBERACION",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "CAMBITA PUEBLO",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "LA CEIBA",
          region: "REGIÓN VIII",
          provincia: "MONSEÑOR NOUEL",
          telefono: "-",
        },
        {
          unap: "JUAN PABLO SEGUNDO",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "PAJARITO",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "GUANANITO",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "LOS TOMINES",
          region: "REGIÓN VII",
          provincia: "SANTIAGO RODRÍGUEZ",
          telefono: "-",
        },
        {
          unap: "RAMONA SANTANA DE LLUBERES",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "LOS MOGOTES",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "LAS GORDAS",
          region: "REGIÓN III",
          provincia: "MARÍA TRINIDAD SANCHEZ",
          telefono: "-",
        },
        {
          unap: "PINO HERRADO",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "CAÑO PIEDRA",
          region: "REGIÓN VIII",
          provincia: "MONSEÑOR NOUEL",
          telefono: "-",
        },
        {
          unap: "PARAISO DE DIOS",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "LA CANELA",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "EL PESCOZON",
          region: "REGIÓN VIII",
          provincia: "SANCHEZ RAMIREZ",
          telefono: "-",
        },
        {
          unap: "HATO NUEVO",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "LA LOMETA",
          region: "REGIÓN III",
          provincia: "MARÍA TRINIDAD SANCHEZ",
          telefono: "-",
        },
        {
          unap: "BIENVENIDO",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "BARRIO NUEVO LA HERRADURA",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "LAS SALINAS",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "CAMBOYA",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "NORMAN RUIZ",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "BRISAS DEL ESTE",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "MOSCU",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "LOS TOROS",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "MANUEL BUENO",
          region: "REGIÓN VII",
          provincia: "DAJABON",
          telefono: "-",
        },
        {
          unap: "HERNANDO ALONZO",
          region: "REGIÓN VIII",
          provincia: "SANCHEZ RAMIREZ",
          telefono: "-",
        },
        {
          unap: "SABANA GRANDE DE PALENQUE",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "VILLA HERMOSA V",
          region: "REGIÓN V",
          provincia: "LA ROMANA",
          telefono: "-",
        },
        {
          unap: "PARCELEROS",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "LAS TARANAS",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "5TO CENTENARIO",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "EL CAIMITO",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "BARRIO OBRERO",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "RINCON HONDO",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "PALMITO",
          region: "REGIÓN IV",
          provincia: "INDEPENDENCIA",
          telefono: "-",
        },
        {
          unap: "PADRE ADOLFO CASADO",
          region: "REGIÓN VIII",
          provincia: "MONSEÑOR NOUEL",
          telefono: "-",
        },
        {
          unap: "VILLA JARAGUA",
          region: "REGIÓN IV",
          provincia: "BAHORUCO",
          telefono: "-",
        },
        {
          unap: "VILLA CARMEN",
          region: "REGIÓN VI",
          provincia: "SAN JUAN DE LA MAGUANA",
          telefono: "-",
        },
        {
          unap: "LOS RANCHITOS",
          region: "REGIÓN VIII",
          provincia: "LA VEGA",
          telefono: "-",
        },
        {
          unap: "RESTAURACIÓN",
          region: "REGIÓN VII",
          provincia: "DAJABON",
          telefono: "-",
        },
        {
          unap: "PAYITA",
          region: "REGIÓN III",
          provincia: "MARÍA TRINIDAD SANCHEZ",
          telefono: "-",
        },
        {
          unap: "VILLA ELISA",
          region: "REGIÓN VII",
          provincia: "MONTE CRISTI",
          telefono: "-",
        },
        {
          unap: "LA RANCHA",
          region: "REGIÓN VI",
          provincia: "SAN JUAN DE LA MAGUANA",
          telefono: "-",
        },
        {
          unap: "MELLA",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "BARRIO NORTE",
          region: "REGIÓN VII",
          provincia: "DAJABON",
          telefono: "-",
        },
        {
          unap: "COMEDERO ARRIBA",
          region: "REGIÓN VIII",
          provincia: "SANCHEZ RAMIREZ",
          telefono: "-",
        },
        {
          unap: "PORVENIR",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "LOS TRANSFORMADORES",
          region: "REGIÓN VI",
          provincia: "SAN JUAN DE LA MAGUANA",
          telefono: "-",
        },
        {
          unap: "LOS CIRUELOS",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "URBANA NAGUA",
          region: "REGIÓN III",
          provincia: "MARÍA TRINIDAD SANCHEZ",
          telefono: "-",
        },
        {
          unap: "EL CACIQUE",
          region: "REGIÓN MONTE PLATA",
          provincia: "MONTE PLATA",
          telefono: "-",
        },
        {
          unap: "PALO AMARILLO",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "RIO ARRIBA",
          region: "REGIÓN I",
          provincia: "PERAVIA",
          telefono: "-",
        },
        {
          unap: "SAN ANTONIO",
          region: "REGIÓN VII",
          provincia: "VALVERDE",
          telefono: "-",
        },
        {
          unap: "FUNDACION NACIONAL EDUCACION Y SOCIEDAD PARA ENVEJECIENTES",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "PLATANAL",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "LOS JOBOS",
          region: "REGIÓN VI",
          provincia: "AZUA",
          telefono: "-",
        },
        {
          unap: "CATAREY",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "FE Y ESPERANZA",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "BALAGUER",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "CAMBITA CRUCE",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "ACTIVO 20 30",
          region: "REGIÓN I",
          provincia: "PERAVIA",
          telefono: "-",
        },
        {
          unap: "LOS TANQUESITOS",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "JUAN ALBERTO ESPINOLA",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "LEONIDAS SOLANO",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "EL LIMONCITO",
          region: "REGIÓN VIII",
          provincia: "SANCHEZ RAMIREZ",
          telefono: "-",
        },
        {
          unap: "LA BOMBITA",
          region: "REGIÓN VI",
          provincia: "AZUA",
          telefono: "-",
        },
        {
          unap: "GONZALO",
          region: "REGIÓN MONTE PLATA",
          provincia: "MONTE PLATA",
          telefono: "-",
        },
        {
          unap: "LA UREÑA",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "SABANA LARGA ABAJO",
          region: "REGIÓN I",
          provincia: "SAN JOSE DE OCOA",
          telefono: "-",
        },
        {
          unap: "LA OTRA BANDA",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "LAS COLES",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "OJO DE AGUA",
          region: "REGIÓN I",
          provincia: "SAN JOSE DE OCOA",
          telefono: "-",
        },
        {
          unap: "BACUI ARRIBA",
          region: "REGIÓN VIII",
          provincia: "LA VEGA",
          telefono: "-",
        },
        {
          unap: "LAS 300 (TRESCIENTAS)",
          region: "REGIÓN VII",
          provincia: "VALVERDE",
          telefono: "-",
        },
        {
          unap: "CONANI",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "LOS GUANDULES",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "SANTA ROSA",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "LAS GUARANAS",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "LA GUAYIGA",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "PALAVE",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "EL CARRIL",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "VIETNAM",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "LA ALTAGRACIA",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "JIMA ARRIBA",
          region: "REGIÓN VIII",
          provincia: "LA VEGA",
          telefono: "-",
        },
        {
          unap: "YAGUITA DE PASTOR",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "SALUD MENTAL",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "LAS FLORES",
          region: "REGIÓN VII",
          provincia: "SANTIAGO RODRÍGUEZ",
          telefono: "-",
        },
        {
          unap: "LA PEÑA",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "HOYA DE CAIMITO",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "5 DE ABRIL",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "LAS FLORES",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "QUIJA QUIETA",
          region: "REGIÓN VI",
          provincia: "SAN JUAN DE LA MAGUANA",
          telefono: "-",
        },
        {
          unap: "LA JOYA",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "LAS FLORES",
          region: "REGIÓN II",
          provincia: "ESPAILLAT",
          telefono: "-",
        },
        {
          unap: "PEDRO JUSTO CARRION",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "LOS LIRIOS",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "LAS CANAS",
          region: "REGIÓN VIII",
          provincia: "SANCHEZ RAMIREZ",
          telefono: "-",
        },
        {
          unap: "CANASTICAS",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "VISTA EL VALLE",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "BUENA VISTA",
          region: "REGIÓN VIII",
          provincia: "LA VEGA",
          telefono: "-",
        },
        {
          unap: "JICOME",
          region: "REGIÓN VII",
          provincia: "VALVERDE",
          telefono: "-",
        },
        {
          unap: "GREGORIO LUPERON",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "CARMEN RENATA",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "LA CUEVA",
          region: "REGIÓN VIII",
          provincia: "SANCHEZ RAMIREZ",
          telefono: "-",
        },
        {
          unap: "VILLA ESTELA",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "SAONA",
          region: "REGIÓN I",
          provincia: "PERAVIA",
          telefono: "-",
        },
        {
          unap: "CARLOS DANIEL",
          region: "REGIÓN VII",
          provincia: "VALVERDE",
          telefono: "-",
        },
        {
          unap: "LA UNION",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "MADRE VIEJA NORTE",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "EL RINCON",
          region: "REGIÓN VIII",
          provincia: "LA VEGA",
          telefono: "-",
        },
        {
          unap: "LA BIJA",
          region: "REGIÓN VIII",
          provincia: "SANCHEZ RAMIREZ",
          telefono: "-",
        },
        {
          unap: "LOS AGUAYOS",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "LAS MAGUANAS",
          region: "REGIÓN VI",
          provincia: "SAN JUAN DE LA MAGUANA",
          telefono: "-",
        },
        {
          unap: "MERCEDES NAVARRO",
          region: "REGIÓN II",
          provincia: "PUERTO PLATA",
          telefono: "-",
        },
        {
          unap: "ONDINA",
          region: "REGIÓN V",
          provincia: "HATO MAYOR",
          telefono: "-",
        },
        {
          unap: "EL VIGIADOR",
          region: "REGIÓN VII",
          provincia: "MONTE CRISTI",
          telefono: "-",
        },
        {
          unap: "EL NARANJAL",
          region: "REGIÓN I",
          provincia: "SAN JOSE DE OCOA",
          telefono: "-",
        },
        {
          unap: "OCTAVIA GAUTIER DE VIDAL",
          region: "REGIÓN VIII",
          provincia: "LA VEGA",
          telefono: "-",
        },
        {
          unap: "PERALVILLO",
          region: "REGIÓN MONTE PLATA",
          provincia: "MONTE PLATA",
          telefono: "-",
        },
        {
          unap: "PAYA",
          region: "REGIÓN I",
          provincia: "PERAVIA",
          telefono: "-",
        },
        {
          unap: "EL GUANO",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "VICENTE NOBLE",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "LA ESTANCIA",
          region: "REGIÓN VI",
          provincia: "SAN JUAN DE LA MAGUANA",
          telefono: "-",
        },
        {
          unap: "VILLA HERMOSA IV",
          region: "REGIÓN V",
          provincia: "LA ROMANA",
          telefono: "-",
        },
        {
          unap: "LAS COLINAS",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "CAMPO LINDO",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "MATA MAMON",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "JIMAYACO",
          region: "REGIÓN VIII",
          provincia: "LA VEGA",
          telefono: "-",
        },
        {
          unap: "HIGUERITO",
          region: "REGIÓN VI",
          provincia: "SAN JUAN DE LA MAGUANA",
          telefono: "-",
        },
        {
          unap: "CRISTO REY",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "CALICHE DE LOS RIOS",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "JUMA",
          region: "REGIÓN VIII",
          provincia: "MONSEÑOR NOUEL",
          telefono: "-",
        },
        {
          unap: "BORUCO",
          region: "REGIÓN VII",
          provincia: "VALVERDE",
          telefono: "-",
        },
        {
          unap: "LA CAYA",
          region: "REGIÓN VII",
          provincia: "VALVERDE",
          telefono: "-",
        },
        {
          unap: "BAOBA DEL PIÑAL",
          region: "REGIÓN III",
          provincia: "MARÍA TRINIDAD SANCHEZ",
          telefono: "-",
        },
        {
          unap: "UNIVERSIDAD DE NORDESTANA",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "VILLA CERRO",
          region: "REGIÓN V",
          provincia: "LA ALTAGRACIA",
          telefono: "-",
        },
        {
          unap: "BARRIO SUR",
          region: "REGIÓN VII",
          provincia: "VALVERDE",
          telefono: "-",
        },
        {
          unap: "ARROYO HONDO",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "CANCA LA PIEDRA",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "GALVAN",
          region: "REGIÓN IV",
          provincia: "BAHORUCO",
          telefono: "-",
        },
        {
          unap: "SAN PABLO APOSTOL",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "LA GALLERA",
          region: "REGIÓN VII",
          provincia: "MONTE CRISTI",
          telefono: "-",
        },
        {
          unap: "SAVICA",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "MAMA TINGO",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "COMEDERO ABAJO",
          region: "REGIÓN VIII",
          provincia: "SANCHEZ RAMIREZ",
          telefono: "-",
        },
        {
          unap: "BARRIO LINDO SPM",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "JIMA ABAJO",
          region: "REGIÓN VIII",
          provincia: "LA VEGA",
          telefono: "-",
        },
        {
          unap: "EL CRUCE DE GUAYACANES",
          region: "REGIÓN VII",
          provincia: "VALVERDE",
          telefono: "-",
        },
        {
          unap: "SAN RAFAEL",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "NUEVA ISABELA",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "LAS ESPINAS",
          region: "REGIÓN VII",
          provincia: "SANTIAGO RODRÍGUEZ",
          telefono: "-",
        },
        {
          unap: "SODECA",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "LA GLORIA",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "EL AHOGADO",
          region: "REGIÓN VII",
          provincia: "MONTE CRISTI",
          telefono: "-",
        },
        {
          unap: "LA UNION",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "SAN GABRIEL",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "NUEVA ESPERANZA",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "SAN JOSE",
          region: "REGIÓN VIII",
          provincia: "MONSEÑOR NOUEL",
          telefono: "-",
        },
        {
          unap: "CEPAE",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "VILLA LIBERACIÓN",
          region: "REGIÓN VI",
          provincia: "SAN JUAN DE LA MAGUANA",
          telefono: "-",
        },
        {
          unap: "LOS MARTIRES",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "LOS CIRUELITOS",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "PRADO COLONIA",
          region: "REGIÓN VI",
          provincia: "AZUA",
          telefono: "-",
        },
        {
          unap: "CESAC DE LA SALLE",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "EL PEDREGAL",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "EL FERNANDEZ",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "ESTANCIA DEL YAQUE",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "LOS FRAILES II",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "CAAMAÑO",
          region: "REGIÓN IV",
          provincia: "BAHORUCO",
          telefono: "-",
        },
        {
          unap: "FUNDACIÓN JOSE MARIA",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "BAITOITA",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "INGENIO SANTA FE",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "COMUNAL LAS PALMAS",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "BAYACANES",
          region: "REGIÓN VIII",
          provincia: "LA VEGA",
          telefono: "-",
        },
        {
          unap: "LA CEIBITA",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "MATA BONITA",
          region: "REGIÓN III",
          provincia: "MARÍA TRINIDAD SANCHEZ",
          telefono: "-",
        },
        {
          unap: "PADRE GRANERO",
          region: "REGIÓN II",
          provincia: "PUERTO PLATA",
          telefono: "-",
        },
        {
          unap: "ARENOSO",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "BOCA DE MAO",
          region: "REGIÓN VII",
          provincia: "VALVERDE",
          telefono: "-",
        },
        {
          unap: "BARRIO LUPERON",
          region: "REGIÓN VII",
          provincia: "DAJABON",
          telefono: "-",
        },
        {
          unap: "LAS CARMELITAS",
          region: "REGIÓN VIII",
          provincia: "LA VEGA",
          telefono: "-",
        },
        {
          unap: "CANCINO ADENTRO",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "ALTO VELO",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "SOSUA",
          region: "REGIÓN II",
          provincia: "PUERTO PLATA",
          telefono: "-",
        },
        {
          unap: "HATO MAYOR",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "MANGANAGUA",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "LOS ALCARRIZOS II",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "CTU",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "BUENOS AIRES",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "LA CUABA",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "BOHECHIO",
          region: "REGIÓN VI",
          provincia: "SAN JUAN DE LA MAGUANA",
          telefono: "-",
        },
        {
          unap: "GUARAGUAO",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "LOS BOTADOS",
          region: "REGIÓN MONTE PLATA",
          provincia: "MONTE PLATA",
          telefono: "-",
        },
        {
          unap: "ENSANCHE BERMUDEZ",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "CLUB 16 DE AGOSTO",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "EL RANCHITO",
          region: "REGIÓN VIII",
          provincia: "LA VEGA",
          telefono: "-",
        },
        {
          unap: "VILLA ESPERANZA",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "VILLA BLANCA",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "BARRIO MÉXICO",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "VILLA ELSA",
          region: "REGIÓN VII",
          provincia: "MONTE CRISTI",
          telefono: "-",
        },
        {
          unap: "SABANA ALTA",
          region: "REGIÓN VI",
          provincia: "SAN JUAN DE LA MAGUANA",
          telefono: "-",
        },
        {
          unap: "EL MAMON",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "CASTILLO URBANO",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "BAYONA",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "SABANA REY",
          region: "REGIÓN VIII",
          provincia: "LA VEGA",
          telefono: "-",
        },
        {
          unap: "IGLESIA SANTA ANA",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "LOS CASABES",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "EL PINO",
          region: "REGIÓN VII",
          provincia: "DAJABON",
          telefono: "-",
        },
        {
          unap: "LA PENUELA",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "BALDEMIRO CARRERAS",
          region: "REGIÓN VII",
          provincia: "SANTIAGO RODRÍGUEZ",
          telefono: "-",
        },
        {
          unap: "JINOVA",
          region: "REGIÓN VI",
          provincia: "SAN JUAN DE LA MAGUANA",
          telefono: "-",
        },
        {
          unap: "BARRIO ENRIQUILLO",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "SAN FELIPE",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "CALETA",
          region: "REGIÓN V",
          provincia: "LA ROMANA",
          telefono: "-",
        },
        {
          unap: "LA ESPERANZA",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "LOS JAVIELES",
          region: "REGIÓN MONTE PLATA",
          provincia: "MONTE PLATA",
          telefono: "-",
        },
        {
          unap: "GUAYACANES",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "ANTI TB INFANTIL",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "HOSTOS",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "JOSUE",
          region: "REGIÓN II",
          provincia: "PUERTO PLATA",
          telefono: "-",
        },
        {
          unap: "ALDEAS INFANTILES SOS",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "PALMARITO",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "EL CAJUILITO",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "PROSPERIDAD",
          region: "REGIÓN VIII",
          provincia: "MONSEÑOR NOUEL",
          telefono: "-",
        },
        {
          unap: "SABANA YEGUA",
          region: "REGIÓN VI",
          provincia: "AZUA",
          telefono: "-",
        },
        {
          unap: "LA PICHINGA",
          region: "REGIÓN III",
          provincia: "MARÍA TRINIDAD SANCHEZ",
          telefono: "-",
        },
        {
          unap: "MARGARA I",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "PUEBLO NUEVO",
          region: "REGIÓN VII",
          provincia: "VALVERDE",
          telefono: "-",
        },
        {
          unap: "GURABO",
          region: "REGIÓN VII",
          provincia: "VALVERDE",
          telefono: "-",
        },
        {
          unap: "LA CIENEGA",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "MAJAGUAL",
          region: "REGIÓN MONTE PLATA",
          provincia: "MONTE PLATA",
          telefono: "-",
        },
        {
          unap: "EL CATEY",
          region: "REGIÓN III",
          provincia: "SAMANÁ",
          telefono: "-",
        },
        {
          unap: "ENSANCHE LUPERON",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "CENTRO COMUNITARIO VILLAS AGRICOLAS",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "SAN FRANCISCO",
          region: "REGIÓN V",
          provincia: "LA ALTAGRACIA",
          telefono: "-",
        },
        {
          unap: "BARRIO DUARTE",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "SAN ANTONIO DE PADUA",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "FE VIOLETA ORTEGA",
          region: "REGIÓN III",
          provincia: "HERMANAS MIRABAL",
          telefono: "-",
        },
        {
          unap: "KOREA",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "VILLA LA MATA",
          region: "REGIÓN VIII",
          provincia: "SANCHEZ RAMIREZ",
          telefono: "-",
        },
        {
          unap: "SAN PEDRO APOSTOL",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "NA",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "VILLA FRANCISCA",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "SAN CARLOS",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "CENTRO COMUNAL",
          region: "REGIÓN V",
          provincia: "LA ROMANA",
          telefono: "-",
        },
        {
          unap: "LAS MERCEDES",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "LA EXPERIENCIA",
          region: "REGIÓN V",
          provincia: "LA ROMANA",
          telefono: "-",
        },
        {
          unap: "LA MINA",
          region: "REGIÓN VII",
          provincia: "VALVERDE",
          telefono: "-",
        },
        {
          unap: "PEDRO CORTO",
          region: "REGIÓN VI",
          provincia: "SAN JUAN DE LA MAGUANA",
          telefono: "-",
        },
        {
          unap: "BAHORUCO",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "DISPENSARIO MARIA AUXILIADORA",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "DAMAJAGUA",
          region: "REGIÓN VII",
          provincia: "VALVERDE",
          telefono: "-",
        },
        {
          unap: "GREGORIO LUPERÓN",
          region: "REGIÓN MONTE PLATA",
          provincia: "MONTE PLATA",
          telefono: "-",
        },
        {
          unap: "QUISQUEYA",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "ZONA B",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "OSCAR SANTANA",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "SABANA DEL PUERTO",
          region: "REGIÓN VIII",
          provincia: "MONSEÑOR NOUEL",
          telefono: "-",
        },
        {
          unap: "PALO ALTO",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "LOS FRAILES I",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "PONCE ADENTRO",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "LA ESPERILLA",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "HOYO DE BARTOLA",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "LAGUNA SALADA",
          region: "REGIÓN VII",
          provincia: "VALVERDE",
          telefono: "-",
        },
        {
          unap: "VILLA FEDERICO",
          region: "REGIÓN I",
          provincia: "SAN CRISTOBAL",
          telefono: "-",
        },
        {
          unap: "LA FURNIA",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "LAS CHARCAS DE MARIA NOVA",
          region: "REGIÓN VI",
          provincia: "SAN JUAN DE LA MAGUANA",
          telefono: "-",
        },
        {
          unap: "LA ROSA DE CENOVI",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "EL TAMARINDO",
          region: "REGIÓN VII",
          provincia: "SANTIAGO RODRÍGUEZ",
          telefono: "-",
        },
        {
          unap: "FUNDAMBIENTE",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "INVI CEA",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "MARIA MONTEZ",
          region: "REGIÓN IV",
          provincia: "BARAHONA",
          telefono: "-",
        },
        {
          unap: "SANTA LUCIA",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "COMUNAL LA ZURZA",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "PROLONGACION VENEZUELA",
          region: "REGIÓN SANTO DOMINGO ESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "EL LIBERTADOR DE HERRERA",
          region: "REGIÓN SANTO DOMINGO OESTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "EL SAMAN",
          region: "REGIÓN VII",
          provincia: "VALVERDE",
          telefono: "-",
        },
        {
          unap: "JUAN DE HERRERA",
          region: "REGIÓN VI",
          provincia: "SAN JUAN DE LA MAGUANA",
          telefono: "-",
        },
        {
          unap: "FELIX TEJADA",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "PADRE MARCILLA",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "SAN NICOLAS",
          region: "REGIÓN II",
          provincia: "SANTIAGO",
          telefono: "-",
        },
        {
          unap: "BATEY ANTONCI",
          region: "REGIÓN MONTE PLATA",
          provincia: "MONTE PLATA",
          telefono: "-",
        },
        {
          unap: "HERMANAS MIRABAL",
          region: "REGIÓN III",
          provincia: "DUARTE",
          telefono: "-",
        },
        {
          unap: "LOS PERALEJOS",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "LOS POMOS",
          region: "REGIÓN VIII",
          provincia: "LA VEGA",
          telefono: "-",
        },
        {
          unap: "GUANUMA",
          region: "REGIÓN SANTO DOMINGO NORTE",
          provincia: "SANTO DOMINGO DE GUZMÁN",
          telefono: "-",
        },
        {
          unap: "EL BUEN PASTOR",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "JAPÓN",
          region: "REGIÓN V",
          provincia: "SAN PEDRO DE MACORIS",
          telefono: "-",
        },
        {
          unap: "PALMA REAL",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
        {
          unap: "GREGORIO LUPERON",
          region: "REGIÓN V",
          provincia: "LA ROMANA",
          telefono: "-",
        },
        {
          unap: "SAN JUAN BAUTISTA",
          region: "REGIÓN DISTRITO NACIONAL",
          provincia: "DISTRITO NACIONAL",
          telefono: "-",
        },
      ],
      evaluacions: [] as Evaluacion[],
      subs: [],
      preSubs: [],
      almacenes: [
        {
          desc: "Almacén No. 01 (General)",
          subs: ["Donaciones", "Venta / Servicios", "Muestras Médicas"],
        },
        {
          desc: "Almacén No. 02 (Suministro de Oficina y Limpieza)",
          subs: [
            "Sub-01 (Materiales de Oficina)",
            "Sub-02 (Materiales de Limpieza)",
          ],
        },
        {
          desc: "Almacén No. 03 (Abastecimiento)",
          subs: [
            "Sub-01 (Carro de Paro UCI)",
            "Sub-02 (Carro de Paro 2do Piso)",
            "Sub-03 (Carro de Paro Perinato)",
            "Sub-04 (Carro de Paro Quirófano)",
            "Sub-05 (Carro de Paro 3er Piso)",
          ],
        },
        {
          desc: "Almacén No. 04 (Emergencia, Quirófano y Ambulatorio)",
          subs: [
            "Sub-01 (Emergencia)",
            "Sub-02 (Depósito Quirófano)",
            "Sub-03 (Imágenes)",
            "Sub-04 (Laboratorio Clínico)",
            "Sub-05 (Laboratorio Biomolecular)",
            "Sub-06 (Farmacia 3er Piso)",
            "Sub-07 (Emergencia Pediatrica)",
          ],
        },
        {
          desc: "Almacén No. 70 (Cocina)",
          subs: [
            "Sub-01 (Alimentos)",
            "Sub-02 (Bebidas y Líquidos)",
            "Sub-03 (Suministros)",
            "Sub-04 (Utencilios)",
          ],
        },
        {
          desc: "Almacén No. 98 (Vencidos)",
          subs: [],
        },
        {
          desc: "Almacén No. 99 (Averiado)",
          subs: [],
        },
      ],
      num1: 0,
      num2: 0,
      fechaActual: "",
      search: "",

      currentEdition: {} as any,

      campoFocus: "cedula",
      mensageError: "Error",
      mensageExito: "Evaluacion Registrado Exitosamente",
      mensageConfirm: "¿Está Seguro que desea Eliminar Este Evaluacion?",
      encabezado: "",
      modoForm: "",
      // funciones: [] as Funcion[],
      showDatosPadre: false,
      showDatosMadre: false,
      showDatosTutor: false,
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      evaluacion: {} as Evaluacion,
      oneEvaluacion: {} as Evaluacion,
      one: {},
      nextNo: Number,
      evaluacionSelected: [],
      clienteSelected: [],
      descripcionCliente: "",

      showInComentary: "",
      showModalEVAL: false,
      showModalSSIRS: false,
      showModal: false,
      showModal7: false,
      showModalAseguradoras: false,
      showDelete: false,
      currentConsulta: "",
      estado: {} as object,
    };
  },

  async mounted() {
    this.filteredCentros = this.centros;
    this.fillFields();
    this.fixTime();
    this.focus();
    this.evaluacion = this.$store.state.user.currentEvaluacion;
  },

  updated() {
    let joined = this.evaluacion.cedula + this.evaluacion.fecha;
    this.$store.state.user.consultasAfiliados = `https://isys-assets-bucket.s3.amazonaws.com/consultasAfiliados/${joined}.jpg`;
    this.$store.state.user.evaluacion = `https://isys-assets-bucket.s3.amazonaws.com/evaluaciones/${joined}.jpg`;
  },

  methods: {
    async deleteEvaluacion() {
      if (confirm("¿Está Seguro que Desea Eliminar Esta Evaluación?")) {
        await this.toggleLoading();
        try {
          const res = await destroy(
            this.evaluacion.cedula,
            this.evaluacion.fecha
          ).then(
            async (res) => {
              await this.toggleLoading();
              this.$router.push("/evaluacions");
            },
            (err) => {
              this.toggleLoading();
              console.error(err);
            }
          );
        } catch (error) {
          console.error(error);
        }
      }
    },

    async uploadFile() {
      this.toggleLoading();
      let input = this.$refs.fileInput;
      let file = input.files;
      const file2 = file[0];
      const url = await getS3Url_cedulafecha(
        this.evaluacion.cedula + this.evaluacion.fecha
      );

      await fetch(url.data, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: file2,
      });
      this.toggleLoading();
      this.evaluacion.consAfil = true;
      this.evaluacion.statusAdministrativo = "VALIDADO";
      this.createEvaluacion();
      this.$router.push("/evaluacions");
    },

    async uploadFileEval() {
      this.toggleLoading();
      let input = this.$refs.fileInputEval;
      let file = input.files;
      const file2 = file[0];
      const url = await getS3Url_cedulafechaEval(
        this.evaluacion.cedula + this.evaluacion.fecha
      );

      await fetch(url.data, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: file2,
      });

      this.toggleLoading();
      this.evaluacion.evaluacion = true;
      this.evaluacion.statusAdministrativo = "EVALUADO";
      this.createEvaluacion();
      this.$router.push("/evaluacions");
    },

    selectImageEval() {
      this.$refs.fileInputEval.click();
    },

    selectImage() {
      this.$refs.fileInput.click();
    },

    handleChangeDireccion() {
      document.getElementById("unap").focus();
    },
    toggleSpinner() {
      this.showSpinner = !this.showSpinner;
    },

    setPatienData(patient: any, patientFechaNacimiento: any) {
      if (patient) {
        this.evaluacion.nombre = patient.nombre;
        this.evaluacion.apellido = patient.apellido;
        this.evaluacion.sexo = patient.sexo.toUpperCase();
        this.evaluacion.fechaNacimiento = this.formatDateToFix(
          patientFechaNacimiento.fechaNacimiento
        );
        this.calcularEdad(this.evaluacion.fechaNacimiento);
        this.evaluacion.nss = patient.nss;
        this.evaluacion.telefono = patient.telefono;
        this.evaluacion.tipo_de_contacto = patient.tipo_de_contacto.toUpperCase();
        this.setContactName();
        document.getElementById("unap").focus();
      }
    },
    defTelefono() {
      if (this.evaluacion.telefono.length == 10) {
        let part1 = this.evaluacion.telefono.substring(0, 3);
        let part2 = this.evaluacion.telefono.substring(3, 6);
        let part3 = this.evaluacion.telefono.substring(6, 10);
        let telefonoGuiones = part1 + "-" + part2 + "-" + part3;
        this.evaluacion.telefono = telefonoGuiones;
      } else if (this.evaluacion.telefono.length == 12) {
        let telefonoGuiones = this.evaluacion.telefono;
        this.evaluacion.telefono = telefonoGuiones;
      } else {
        alert("Teléfono Incorrecto");
        this.evaluacion.telefono = "";
      }
    },
    async defCedula() {
      if (this.evaluacion.cedula.length == 11) {
        let part1 = this.evaluacion.cedula.substring(0, 3);
        let part2 = this.evaluacion.cedula.substring(3, 10);
        let part3 = this.evaluacion.cedula.substring(10, 11);
        // alert(part1)
        // alert(part2)
        // alert(part3)
        let cedulaGuiones = part1 + "-" + part2 + "-" + part3;
        this.evaluacion.cedula = cedulaGuiones.trim();

        this.toggleSpinner();
        try {
          const patientData = await getPatient(this.evaluacion.cedula);
          const patientFechaNacimientoData = await getPatientFechaNacimiento(
            this.evaluacion.cedula
          );
          if (
            patientData.data.title == "Usuario no Encontrado" ||
            patientFechaNacimientoData.data.title == "Usuario no Encontrado"
          ) {
            // alert('No Encontrado')
          } else {
            alert(JSON.stringify(patientData.data));
            this.setPatienData(
              patientData.data.Items[0],
              patientFechaNacimientoData.data.Items[0]
            );
          }
        } catch (error) {
          console.log(error);
        }
        await this.toggleSpinner();

        document.getElementById("unap").focus();
      } else if (this.evaluacion.cedula.length == 13) {
        this.toggleSpinner();
        try {
          const patientData = await getPatient(this.evaluacion.cedula);
          const patientFechaNacimientoData = await getPatientFechaNacimiento(
            this.evaluacion.cedula
          );
          this.setPatienData(
            patientData.data.Items[0],
            patientFechaNacimientoData.data.Items[0]
          );
        } catch (error) {
          console.log(error);
        }
        await this.toggleSpinner();

        document.getElementById("unap").focus();
      } else {
        alert("Por Favor, Verifique la Cédula");
        document.getElementById("cedula").focus();
      }
    },
    setContactName() {
      if (this.evaluacion.tipo_de_contacto == "PROPIO") {
        this.evaluacion.nombre_contacto =
          this.evaluacion.nombre + " " + this.evaluacion.apellido;
      } else {
        this.evaluacion.nombre_contacto = "";
      }
    },
    numeroAFecha(numeroDeDias: any, esExcel = false) {
      var diasDesde1900 = esExcel ? 25567 + 1 : 25567 + 2;

      // 86400 es el número de segundos en un día, luego multiplicamos por 1000 para obtener milisegundos.
      return new Date((numeroDeDias - diasDesde1900) * 86400 * 1000);
    },

    setService() {
      if (this.evaluacion.tipServ == "Consulta") {
        this.evaluacion.descServ = "Consulta";
      } else {
        this.evaluacion.descServ = "";
      }
    },

    showModalAseguradorasMethod() {
      this.seguros2 = this.seguros;
      this.showModalAseguradoras = !this.showModalAseguradoras;
    },

    async redirect(id: string) {
      // alert("Redirecting");
      this.$router.push(`/evaluacions/${id}`);

      // Remounted
      this.modoForm = "show";
      this.encabezado = "Detalles de Evaluacion";
      this.showDeleteMethod();
      await this.loadEvaluacion(id);
      this.defSubs();

      // this.evaluacion.finVac = new Date();
      // this.evaluacion.fechaNac = new Date();
      // this.evaluacion.fechaCont = new Date();
      this.fixTime();
      this.focus();

      this.showModalMethod7();
    },

    async buscando() {
      this.toggleLoading();

      this.toggleLoading();
    },

    async changeFocus() {
      document.getElementById("nombPres").focus();
      await this.buscando();
      this.showModalMethod7();
    },
    calcVenc(date: any) {
      this.evaluacion.mesVenc = moment(date).format("MM");
      this.evaluacion.anoVenc = moment(date).format("YYYY");
    },

    setCentro(centro: any) {
      this.evaluacion.unap = centro.unap;
      this.evaluacion.region = centro.region;
      this.evaluacion.provincia = centro.provincia;
      this.evaluacion.municipio = centro.municipio;
      this.evaluacion.id_center = centro.id_center;
      this.evaluacion.id_centerKit =
        this.evaluacion.id_center + "-" + centro.id_center;
      this.$store.state.user.id_center = centro.id_center;
      this.showModalAseguradorasMethod();
      this.search = "";
      document.getElementById("nombre").focus();
      this.filteredCentros = this.centros;
      this.lockCentro = true;
    },

    buscar(term: string) {
      this.filteredCentros = this.centros;
      if (term !== "") {
        this.filteredCentros = this.centros.filter((centro: any) => {
          return centro.unap.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      if (this.filteredCentros.length == 1) {
        if (
          confirm(
            "¿ DESEA SELECCIONAR " +
              this.filteredCentros[0].unap +
              " DE " +
              this.filteredCentros[0].region +
              ", PROVINCIA " +
              this.filteredCentros[0].provincia +
              "?"
          )
        ) {
          this.setCentro(this.filteredCentros[0]);
        }
      }
    },

    showModalEVALMethod() {
      this.showModalEVAL = !this.showModalEVAL;
    },

    showModalSSIRSMethod() {
      this.showModalSSIRS = !this.showModalSSIRS;
    },

    showModalMethod7() {
      this.seguros2 = this.seguros;
      this.showModal7 = !this.showModal7;
      // document.getElementById("oficio").focus();
    },

    showModalConfirmMethod() {
      this.showModalConfirm = !this.showModalConfirm;
      document.getElementById("cedula").focus();
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    deleteService(it: any) {
      if (confirm("Está Seguro que desea Eliminar Esta Consulta?")) {
        this.evaluacion.consultas.splice(it, 1);
      }
    },

    editService(index: number, consulta: any) {
      this.currentEdition = consulta;
      this.showModalMethod();
    },

    newFormatDate(dateValue: Date) {
      // let out = moment(dateValue).add(0, "h");
      // return moment(out).format("DD/MM/YYYY");
      moment.locale("es");
      return moment(dateValue).calendar();
      // .startOf("hour")
      // .fromNow();
    },

    async addActivity() {
      if (this.currentConsulta !== "") {
        this.evaluacion.consultas.push({
          description: this.currentConsulta,
          date: new Date(),
        });
        this.currentConsulta = "";
      }
      document.getElementById("barcode").focus();
    },

    calcularEdad(date: any) {
      let years = 0;
      let edad = Math.floor(
        moment(new Date()).diff(moment(date, "YYYY-MM-DD"), "years", true)
      );
      if (edad > 120 || edad < 0) {
        years = 0;
      } else {
        years = edad;
      }
      this.evaluacion.edad = years;
    },

    async loadEvaluacions() {
      this.toggleLoading();

      this.toggleLoading();
    },

    fixTime() {
      if (this.evaluacion.fecha) {
        this.evaluacion.fecha = this.formatDateToFix(
          this.evaluacion.fecha,
          false
        );
      }
    },

    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        let out = moment(dateValue).add(0, "hours");
        return moment(out).format("yyyy-MM-DD");
      }
    },

    async loadEvaluacion(index: number) {
      this.evaluacion = this.$store.state.user.defaultStatus[index];
    },

    showDeleteMethod() {
      if (this.$store.state.user.type == "Power User") {
        this.showDelete = true;
      }
    },

    toggleShowDatosPadre() {
      this.showDatosPadre = !this.showDatosPadre;
    },
    toggleShowDatosMadre() {
      this.showDatosMadre = !this.showDatosMadre;
    },
    toggleShowDatosTutor() {
      this.showDatosTutor = !this.showDatosTutor;
    },

    isError(message: string) {
      if (message == this.mensageExito) {
        return "success";
      } else {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcEvaluacion() {
      (this.evaluacion.retension = this.evaluacion.bruto * 0.1),
        (this.evaluacion.neto = this.evaluacion.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    // formatDate(dateValue: Date) {
    //   let out = moment(dateValue).add(4, "hours");
    //   return moment(out).format("MM");
    // },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "hours");
      return moment(out).format("YYYY-MM-DD");
    },

    formatInvertedDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "hours");
      return moment(out).format("MM/DD/YYYY");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fillFields() {
      this.evaluacion.consAfil = false;
      this.evaluacion.evaluacion = false;
      this.evaluacion.estado = "ACTIVO";
      this.evaluacion.fecha = new Date();
      if (this.$store.state.user.type == "Supervisor en Sitio") {
        this.evaluacion.supervisor = this.$store.state.user.usuario;
      }
      if (this.$store.state.user.type == "Médico Nutricionista") {
        this.evaluacion.nombre_doctor = this.$store.state.user.usuario;
      }
      this.evaluacion.regimen = "SUBSIDIADO";
    },

    async saveEvaluaciona() {
      await this.loadOneEvaluacion();
      // try {
      //   const res = await createEvaluaciona(this.evaluacion);
      //   // // console.log(res);
      // } catch (error) {
      //   console.error(error);
      // }
    },

    async createEvaluacion() {
      if (
        this.evaluacion.fecha &&
        this.evaluacion.cedula &&
        this.evaluacion.nombre &&
        this.evaluacion.apellido &&
        this.evaluacion.sexo &&
        this.evaluacion.fechaNacimiento &&
        this.evaluacion.edad &&
        this.evaluacion.nss &&
        this.evaluacion.regimen &&
        this.evaluacion.direccion &&
        this.evaluacion.telefono &&
        this.evaluacion.tipo_de_contacto &&
        this.evaluacion.nombre_contacto &&
        this.evaluacion.supervisor &&
        this.evaluacion.unap
      ) {
        this.toggleLoading();
        this.evaluacion.fecha = this.formatDate(this.evaluacion.fecha);
        try {
          const res = await create(this.evaluacion).then(
            async (res) => {
              this.cleanFields();
              this.fillFields();
              this.fixTime();
              await this.toggleLoading();
              // this.$router.push(`/evaluacions`);
              if (res.data.Status == "Success") {
                // alert("Evaluación Generada Exitosamente");
                this.showModalConfirmMethod();
              }
            },
            (err) => {
              alert(err);
            }
          );
          // this.$router.push(`/facturaas2/print`);
          // // console.log(res);
        } catch (error) {
          console.error(error);
        }

        document.getElementById(this.campoFocus).focus();
      } else {
        alert("Asegúrece de llenar todos los Campos");
      }
    },

    // async deleteAllEvaluacions() {
    //   try {
    //     const res = await eliminateEvaluacions(this.evaluacion);
    //     // // console.log(res);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },

    cleanFields() {
      this.evaluacion.nombre = "";
      this.evaluacion.apellido = "";
      this.evaluacion.sexo = "";
      this.evaluacion.cedula = "";
      this.evaluacion.nss = "";
      this.evaluacion.regimen = "";
      this.evaluacion.telefono = "";
      this.evaluacion.tipo_de_contacto = "";
      this.evaluacion.nombre_contacto = "";
      this.evaluacion.especificacion_contacto = "";
      this.evaluacion.region = "";
      this.evaluacion.provincia = "";
      this.evaluacion.municipio = "";
      this.evaluacion.estado = "";
      this.evaluacion.talla = "";
      this.evaluacion.peso = "";
      this.evaluacion.cintura = "";
      this.evaluacion.abdomen = "";
      this.evaluacion.cadera = "";
      this.evaluacion.altura_rodilla = "";

      this.evaluacion.presion_arterial_sistolica = "";
      this.evaluacion.presion_arterial_diatolica = "";
      this.evaluacion.frecuencia_cardiaca = "";
      this.evaluacion.fuerza = "";
      this.evaluacion.imc = "";
      this.evaluacion.flexibilidad_miembro_superior = "";
      this.evaluacion.flexibilidad_miembro_inferior = "";
      this.evaluacion.pliegue_bicep = "";
      this.evaluacion.pliegue_abdominal = "";
      this.evaluacion.pliegue_sub = "";
      this.evaluacion.Encamado = "";
      this.evaluacion.Edentulo = "";

      this.evaluacion.abandono = "";
      this.evaluacion.maltrato = "";

      this.evaluacion.specialist_appo_psiqui = "";
      this.evaluacion.specialist_appo_geria = "";
      this.evaluacion.specialist_appo_orto = "";
      this.evaluacion.specialist_appo_gastro = "";
      this.evaluacion.specialist_appo_oftal = "";
      this.evaluacion.specialist_appo_psicolo = "";
      this.evaluacion.specialist_appo_neurolo = "";
      this.evaluacion.specialist_appo_fisia = "";
      this.evaluacion.specialist_appo_cardio = "";
      this.evaluacion.specialist_appo_odonto = "";
      this.evaluacion.specialist_appo_urolo = "";
      this.evaluacion.specialist_appo_diabe = "";

      this.evaluacion.fecha = "";
      this.evaluacion.kit = "";
      this.evaluacion.nombre_doctor = "";
      this.evaluacion.comentary = "";
      this.evaluacion.eval_nutri_1 = "";
      this.evaluacion.eval_nutri_2 = "";
      this.evaluacion.eval_nutri_3 = "";
      this.evaluacion.eval_nutri_4 = "";
      this.evaluacion.eval_nutri_5 = "";
      this.evaluacion.eval_nutri_6 = "";
      this.evaluacion.eval_nutri_7 = "";
      this.evaluacion.eval_nutri_8 = "";
      this.evaluacion.eval_nutri_9 = "";
      this.evaluacion.eval_nutri_10 = "";
      this.evaluacion.fechaNacimiento = "";
      this.evaluacion.edad = "";
      this.evaluacion.direccion = "";
      this.evaluacion.status = "";
      this.evaluacion.id_center = "";
      this.evaluacion.id_patient = "";
      this.evaluacion.id_form = "";
      this.evaluacion.id_doctor = "";
      this.evaluacion.precio = "";

      this.evaluacion.autorizacion = "";
      this.evaluacion.pctoGrasa = "";
      this.evaluacion.estadoImc = "";
      this.evaluacion.estadoFuerza = "";
      this.evaluacion.riesgocardiovascular = "";
      this.evaluacion.estadofc = "";
      this.evaluacion.cambioKit = "";
      this.evaluacion.justificacionCambioKit = "";
      this.evaluacion.riesgoNutricional = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById(this.campoFocus).focus();
    },
    focus2() {
      document.getElementById(this.campoFocus2).focus();
    },
  },
});
