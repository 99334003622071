
import Filters from "@/components/Empleado/FiltersComponent.vue";
import ListadoComponent from "@/components/Empleado/ListComponent.vue";
import Navbar from "@/components/Navbar.vue";
import { Empleado } from "@/interfaces/Empleado";
import { getAll } from "@/services/empleado/Empleado";
import { getS3Url_cedulafecha } from '@/services/s3/s3Service';
export default {
  name: "App",

  components: {
    Filters,
    ListadoComponent,
    Navbar,
  },

  data() {
    return {
      player: new Audio(),
      showModal: false,
      cargando: false,
      empleados: [] as Empleado[],
      str: "",
      type: "",
    };
  },

  methods: {
    showModalMethod() {
      this.showModal = !this.showModal;
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    async filterEmpleados(catName: string) {
      try {
        const res = await getAll();
        this.empleados = res.data;
      } catch (error) {
        console.error(error);
      }
      if (catName !== "Todos") {
        this.empleados = this.empleados.filter((empleado: Empleado) => {
          return empleado.empresa === catName;
        });
      }
    },

    async search(term: string) {
      this.toggleLoading();
      try {
        const res = await getAll();
        this.empleados = res.data;
      } catch (error) {
        console.error(error);
      }
      if (term !== "Todos") {
        this.empleados = this.empleados.filter((empleado: Empleado) => {
          return empleado.empresa.toLowerCase().includes(term.toLowerCase());
        });
      }
      this.toggleLoading();
    },

    async loadEmpleados() {
      this.toggleLoading();
      try {
        const res = await getAll();
        this.empleados = res.data.empleados;
        this.$store.state.user.proxCodEmpleado = res.data.empleados.length;
      } catch (error) {
        console.error(error);
      }
      this.toggleLoading();
    },

    async loadEmpleados2() {
      this.toggleLoading();
      try {
        const res = await getAll();
        this.empleados = res.data;
      } catch (error) {
        console.error(error);
      }
      this.toggleLoading();
    },
  },

  mounted() {
    this.loadEmpleados();
  },
};
