
export default {
  props: ["filterEmpleados", "search", "filteredEmpleados"],

  data() {
    return {
      term: "",
      filters2: [] as [],
    };
  },

};
