
import Navbar from "@/components/Navbar.vue";
import { getAll } from "@/services/centro/Centro";
import { getS3Url_cedulafecha } from "@/services/s3/s3Service";
import {
  getManyByDate,
  getManyByCedula,
  getOne,
} from "@/services/evaluacion/Evaluacion";
import { defineComponent } from "vue";
import { Evaluacion } from "@/interfaces/Evaluacion";
import { create } from "@/services/evaluacion/Evaluacion";
import { getEvaluacions } from "@/services/elderstatistic/Evaluacion";
import {
  getPatient,
  getPatientFechaNacimiento,
} from "@/services/elderstatistic/UsuarioService";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "evaluacions-form",
  components: {
    Navbar,
  },
  data() {
    return {
      estadoLoading: "Cargando...",
      cantEvalPac: 0,
      showModalConfirm: false,
      showSpinner: false,
      lockCentro: false,
      filteredCentros: [],
      evaluacions: [] as Evaluacion[],
      subs: [],
      preSubs: [],
      num1: 0,
      num2: 0,
      fechaActual: "",
      search: "",
      seguros2: [],
      currentEdition: {} as any,
      campoFocus: "cedula",
      mensageError: "Error",
      mensageExito: "Evaluacion Registrado Exitosamente",
      mensageConfirm: "¿Está Seguro que desea Eliminar Este Evaluacion?",
      encabezado: "",
      modoForm: "",
      // funciones: [] as Funcion[],
      showDatosPadre: false,
      showDatosMadre: false,
      showDatosTutor: false,
      message: {
        username: "Ronnald",
        message: "Hola",
      },
      documento: {} as any,
      error: "",
      respuesta: {},
      actividad: "",
      showAlert: false,
      loadedAfiliado: {},
      cargando: false,
      evaluacion: {} as Evaluacion,
      oneEvaluacion: {} as Evaluacion,
      one: {},
      nextNo: Number,
      evaluacionSelected: [],
      clienteSelected: [],
      descripcionCliente: "",

      showInComentary: "",
      showModal: false,
      showModal7: false,
      showModalAseguradoras: false,
      showDelete: false,
      currentConsulta: "",
      estado: {} as object,
    };
  },

  async mounted() {
    if (!this.$store.state.user.id_center) {
      this.loadCentros();
      document.getElementById("unap").focus();
    }
    this.fillFields();
    this.fixTime();
  },

  updated() {
    if (this.evaluacion.nombre) {
      this.evaluacion.nombre = this.evaluacion.nombre.toUpperCase();
    }
    if (this.evaluacion.apellido) {
      this.evaluacion.apellido = this.evaluacion.apellido.toUpperCase();
    }
    if (this.evaluacion.direccion) {
      this.evaluacion.direccion = this.evaluacion.direccion.toUpperCase();
    }
    if (this.evaluacion.especificacion_contacto) {
      this.evaluacion.especificacion_contacto = this.evaluacion.especificacion_contacto.toUpperCase();
    }
    if (this.evaluacion.nombre_contacto) {
      this.evaluacion.nombre_contacto = this.evaluacion.nombre_contacto.toUpperCase();
    }
    if (this.evaluacion.comentary) {
      this.evaluacion.comentary = this.evaluacion.comentary.toUpperCase();
    }

    let joined = this.evaluacion.cedula + this.evaluacion.fecha;
    this.$store.state.user.consultasAfiliados = `https://isys-assets-bucket.s3.amazonaws.com/consultasAfiliados/${joined}.jpg`;
  },

  methods: {
    async consExistEvaluacion() {
      try {
        const res = await getOne(
          this.evaluacion.cedula,
          this.evaluacion.fecha
        ).then(
          async (res) => {
            if (res.data.Count == 1) {
              alert("Este paciente ya tiene una Evaluación");
              this.evaluacion.cedula = "";
              document.getElementById("cedula").focus();
            }
          },
          (err) => {
            console.error(err);
          }
        );
      } catch (error) {
        console.error(error);
      }
    },

    async loadCentros() {
      this.estadoLoading = "Cargando Listado de Centros...";
      this.toggleLoading();
      try {
        const res = await getAll();
        this.$store.state.user.centros = res.data.centros;
        this.$store.state.user.filteredCentros = res.data.centros;
      } catch (error) {
        console.error(error);
      }

      this.toggleLoading();
      this.estadoLoading = "Cargando...";
    },

    fechaState() {
      this.consExistEvaluacion();
      this.$store.state.user.desde = this.evaluacion.fecha;
    },

    async uploadFile() {
      this.estadoLoading = "Cargando Escáner SIRS...";
      this.toggleLoading();
      let input = this.$refs.fileInput;
      let file = input.files;
      const file2 = file[0];
      const url = await getS3Url_cedulafecha(
        this.evaluacion.cedula + this.evaluacion.fecha
      );

      await fetch(url.data, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: file2,
      });
      this.toggleLoading();
      this.estadoLoading = "Cargando...";
      this.evaluacion.consAfil = true;
      this.evaluacion.statusAdministrativo = "VALIDADO";
      this.createEvaluacion();
    },

    selectImage() {
      this.$refs.fileInput.click();
    },

    handleChangeDireccion() {
      document.getElementById("unap").focus();
    },
    toggleSpinner() {
      this.showSpinner = !this.showSpinner;
    },

    setPatienData(patient: any, patientFechaNacimiento: any) {
      if (patient) {
        this.evaluacion.nombre = patient.nombre;
        this.evaluacion.apellido = patient.apellido;
        this.evaluacion.sexo = patient.sexo.toUpperCase();
        this.evaluacion.fechaNacimiento = this.formatDateToFix(
          patientFechaNacimiento.fechaNacimiento
        );
        this.calcularEdad(this.evaluacion.fechaNacimiento);
        this.evaluacion.nss = patient.nss;
        this.evaluacion.telefono = patient.telefono;
        this.evaluacion.tipo_de_contacto = patient.tipo_de_contacto.toUpperCase();
        this.setContactName();
        document.getElementById("unap").focus();
      }
    },
    defTelefono() {
      if (this.evaluacion.telefono.length == 10) {
        let part1 = this.evaluacion.telefono.substring(0, 3);
        let part2 = this.evaluacion.telefono.substring(3, 6);
        let part3 = this.evaluacion.telefono.substring(6, 10);
        let telefonoGuiones = part1 + "-" + part2 + "-" + part3;
        this.evaluacion.telefono = telefonoGuiones;
      } else if (this.evaluacion.telefono.length == 12) {
        let telefonoGuiones = this.evaluacion.telefono;
        this.evaluacion.telefono = telefonoGuiones;
      } else {
        alert("Teléfono Incorrecto");
        this.evaluacion.telefono = "";
      }
    },
    async defCedula() {
      if (this.evaluacion.cedula.length == 11) {
        let part1 = this.evaluacion.cedula.substring(0, 3);
        let part2 = this.evaluacion.cedula.substring(3, 10);
        let part3 = this.evaluacion.cedula.substring(10, 11);
        let cedulaGuiones = part1 + "-" + part2 + "-" + part3;
        this.evaluacion.cedula = cedulaGuiones.trim();

        this.toggleSpinner();

        try {
          await this.consExistEvaluacion();
          const patientData = await getPatient(this.evaluacion.cedula);
          const patientFechaNacimientoData = await getPatientFechaNacimiento(
            this.evaluacion.cedula
          );
          if (
            patientData.data.title == "Usuario no Encontrado" ||
            patientFechaNacimientoData.data.title == "Usuario no Encontrado"
          ) {
            // alert('No Encontrado')
          } else {
            // alert(JSON.stringify(patientData.data));
            this.setPatienData(
              patientData.data.Items[0],
              patientFechaNacimientoData.data.Items[0]
            );
          }
        } catch (error) {
          console.log(error);
        }
        await this.toggleSpinner();

        document.getElementById("unap").focus();
      } else if (this.evaluacion.cedula.length == 13) {
        this.toggleSpinner();
        try {
          await this.consExistEvaluacion();
          const patientData = await getPatient(this.evaluacion.cedula);
          const patientFechaNacimientoData = await getPatientFechaNacimiento(
            this.evaluacion.cedula
          );
          this.setPatienData(
            patientData.data.Items[0],
            patientFechaNacimientoData.data.Items[0]
          );
        } catch (error) {
          console.log(error);
        }
        await this.toggleSpinner();

        document.getElementById("unap").focus();
      } else {
        alert("Por Favor, Verifique la Cédula");
        document.getElementById("cedula").focus();
      }
    },
    setContactName() {
      if (this.evaluacion.tipo_de_contacto == "PROPIO") {
        this.evaluacion.nombre_contacto =
          this.evaluacion.nombre + " " + this.evaluacion.apellido;
      } else {
        this.evaluacion.nombre_contacto = "";
      }
    },
    numeroAFecha(numeroDeDias: any, esExcel = false) {
      var diasDesde1900 = esExcel ? 25567 + 1 : 25567 + 2;

      // 86400 es el número de segundos en un día, luego multiplicamos por 1000 para obtener milisegundos.
      return new Date((numeroDeDias - diasDesde1900) * 86400 * 1000);
    },

    setService() {
      if (this.evaluacion.tipServ == "Consulta") {
        this.evaluacion.descServ = "Consulta";
      } else {
        this.evaluacion.descServ = "";
      }
    },

    showModalAseguradorasMethod() {
      this.seguros2 = this.seguros;
      this.showModalAseguradoras = !this.showModalAseguradoras;
    },

    async redirect(id: string) {
      // alert("Redirecting");
      this.$router.push(`/evaluacions/${id}`);

      // Remounted
      this.modoForm = "show";
      this.encabezado = "Detalles de Evaluacion";
      this.showDeleteMethod();
      await this.loadEvaluacion(id);
      this.defSubs();
      this.fixTime();
      this.focus();

      this.showModalMethod7();
    },

    calcVenc(date: any) {
      this.evaluacion.mesVenc = moment(date).format("MM");
      this.evaluacion.anoVenc = moment(date).format("YYYY");
    },

    setCentro(centro: any) {
      this.evaluacion.unap = centro.unap;
      this.evaluacion.region = centro.region;
      this.evaluacion.provincia = centro.provincia;
      this.evaluacion.municipio = centro.municipio;
      this.evaluacion.id_center = centro.id_center;
      this.evaluacion.id_centerKit = this.evaluacion.id_center;
      this.$store.state.user.id_center = centro.id_center;
      this.$store.state.user.unap = centro.unap;
      this.showModalAseguradorasMethod();
      this.search = "";
      document.getElementById("cedula").focus();
      // this.filteredCentros = this.centros;
      this.lockCentro = true;
    },

    buscar(term: string) {
      this.$store.state.user.filteredCentros = this.$store.state.user.centros;
      if (term !== "") {
        this.$store.state.user.filteredCentros = this.$store.state.user.centros.filter(
          (centro: any) => {
            return centro.unap
              .toLowerCase()
              .includes(this.search.toLowerCase());
          }
        );
      }
    },

    showModalMethod7() {
      this.seguros2 = this.seguros;
      this.showModal7 = !this.showModal7;
    },

    showModalConfirmMethod() {
      this.showModalConfirm = !this.showModalConfirm;
      document.getElementById("cedula").focus();
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    deleteService(it: any) {
      if (confirm("Está Seguro que desea Eliminar Esta Consulta?")) {
        this.evaluacion.consultas.splice(it, 1);
      }
    },

    editService(index: number, consulta: any) {
      this.currentEdition = consulta;
      this.showModalMethod();
    },

    newFormatDate(dateValue: Date) {
      moment.locale("es");
      return moment(dateValue).calendar();
    },

    async addActivity() {
      if (this.currentConsulta !== "") {
        this.evaluacion.consultas.push({
          description: this.currentConsulta,
          date: new Date(),
        });
        this.currentConsulta = "";
      }
      document.getElementById("barcode").focus();
    },

    calcularEdad(date: any) {
      let years = 0;
      let edad = Math.floor(
        moment(new Date()).diff(moment(date, "YYYY-MM-DD"), "years", true)
      );
      if (edad > 120 || edad < 0) {
        years = 0;
      } else {
        years = edad;
      }
      this.evaluacion.edad = years;
    },

    fixTime() {
      if (this.evaluacion.fecha) {
        this.evaluacion.fecha = this.formatDateToFix(
          this.evaluacion.fecha,
          false
        );
      }
      if (this.$store.state.user.desde) {
        this.$store.state.user.desde = this.formatDateToFix(
          this.$store.state.user.desde,
          false
        );
      }
    },

    formatDateToFix(dateValue: Date, incTime: boolean) {
      if (incTime == true) {
        let out = moment(dateValue).add(0, "days");
        return moment(out).format("yyyy-MM-DDTHH:mm");
      } else {
        let out = moment(dateValue).add(0, "hours");
        return moment(out).format("yyyy-MM-DD");
      }
    },

    async loadEvaluacion(index: number) {
      this.evaluacion = this.$store.state.user.defaultStatus[index];
    },

    showDeleteMethod() {
      if (this.$store.state.user.type == "Power User") {
        this.showDelete = true;
      }
    },

    toggleShowDatosPadre() {
      this.showDatosPadre = !this.showDatosPadre;
    },
    toggleShowDatosMadre() {
      this.showDatosMadre = !this.showDatosMadre;
    },
    toggleShowDatosTutor() {
      this.showDatosTutor = !this.showDatosTutor;
    },

    isError(message: string) {
      if (message == this.mensageExito) {
        return "success";
      } else {
        return "error";
      }
    },

    toggleAlert() {
      this.showAlert = !this.showAlert;
    },
    calcEvaluacion() {
      (this.evaluacion.retension = this.evaluacion.bruto * 0.1),
        (this.evaluacion.neto = this.evaluacion.bruto * 0.9);
    },
    formatNumber(value: number) {
      return numeral(value).format("00000000");
    },

    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "hours");
      return moment(out).format("YYYY-MM-DD");
    },

    formatInvertedDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "hours");
      return moment(out).format("MM/DD/YYYY");
    },

    formatDatePlus(dateValue: Date) {
      let out = moment(dateValue).add(30, "days");
      return moment(out).format("yyyy-MM-DTHH:mm");
    },

    fillFields() {
      this.evaluacion.consAfil = false;

      if (this.$store.state.user.id_center) {
        this.evaluacion.unap = this.$store.state.user.unap;
        this.evaluacion.region = this.$store.state.user.region;
        this.evaluacion.provincia = this.$store.state.user.provincia;
        this.evaluacion.municipio = this.$store.state.user.municipio;
        this.evaluacion.id_centerKit = this.$store.state.user.id_centerKit;

        this.evaluacion.id_center = this.$store.state.user.id_center;
        this.lockCentro = true;
      } else {
        document.getElementById("unap").focus();
      }

      this.evaluacion.estado = "ACTIVO";

      if (!this.$store.state.user.desde) {
        this.evaluacion.fecha = new Date();
      } else {
        this.evaluacion.fecha = this.$store.state.user.desde;
      }

      this.fechaState();
      if (this.$store.state.user.type == "Supervisor en Sitio") {
        this.evaluacion.supervisor = this.$store.state.user.usuario;
      }
      if (this.$store.state.user.type == "Médico Nutricionista") {
        this.evaluacion.nombre_doctor = this.$store.state.user.usuario;
      }
      this.evaluacion.regimen = "SUBSIDIADO";

      this.evaluacion.id_form = 0;

      this.evaluacion.specialist_appo_psiqui = false;
      this.evaluacion.specialist_appo_geria = false;
      this.evaluacion.specialist_appo_orto = false;
      this.evaluacion.specialist_appo_gastro = false;
      this.evaluacion.specialist_appo_oftal = false;
      this.evaluacion.specialist_appo_psicolo = false;
      this.evaluacion.specialist_appo_neurolo = false;
      this.evaluacion.specialist_appo_fisia = false;
      this.evaluacion.specialist_appo_cardio = false;
      this.evaluacion.specialist_appo_odonto = false;
      this.evaluacion.specialist_appo_urolo = false;
      this.evaluacion.specialist_appo_diabe = false;

      this.evaluacion["Andador 4 Ruedas"] = false;
      this.evaluacion["Andador 4 Patas"] = false;
      this.evaluacion["Bastón"];
      this.evaluacion["Bastón 4 P"] = false;
      this.evaluacion["Bastón No VIdente"] = false;
      this.evaluacion["Cama de Posición"] = false;
      this.evaluacion["Colchón Aire"] = false;
      this.evaluacion["Faja Obdominal"] = false;
      this.evaluacion["Glucómetro"];
      this.evaluacion["Inodoro Porta"] = false;
      this.evaluacion["Muletas"];
      this.evaluacion["Pampers XL"] = false;
      this.evaluacion["Pampers L"] = false;
      this.evaluacion["Pampers M"] = false;
      this.evaluacion["Pampers S"] = false;
      this.evaluacion["Silla de Baño"] = false;
      this.evaluacion["Media de Comprensión"] = false;

      this.evaluacion.cambiokit = false;
      this.evaluacion.status = "ACTIVO";

      this.evaluacion.precio = 0;

      this.evaluacion.estadofc = "";
      this.evaluacion.riesgocardiovascular = "";

      this.evaluacion.autorizacion = "";
      this.evaluacion.statusAdministrativo = "";

      this.evaluacion.comentary = "";
      this.evaluacion.comentaryNutri = "";
      this.evaluacion.justificacioncambiokit = "";

      this.evaluacion.id_doctor = "";
      this.evaluacion.id_patient = "";
    },

    async createEvaluacion() {
      this.estadoLoading = "Consultando Evaluaciónes Anteriores...";
      this.toggleLoading();
      const res = await getManyByCedula(this.evaluacion.cedula);

      this.toggleLoading();
      this.estadoLoading = "Cargando...";
      this.cantEvalPac = res.data.Count;
      if (this.cantEvalPac == 9) {
        alert("Este Paciente ya Cuenta con 9 Evaluaciones. Está dado de Alta.");
      } else if (this.cantEvalPac == 8) {
        alert(
          "Esta es la Evaluación para el Alta del Paciente, Asegúrece de Verificar el Kit de Alta"
        );

        if (this.evaluacion.nss.length == 9) {
          if (
            this.evaluacion.fecha &&
            this.evaluacion.cedula &&
            this.evaluacion.nombre &&
            this.evaluacion.apellido &&
            this.evaluacion.sexo &&
            this.evaluacion.fechaNacimiento &&
            this.evaluacion.edad &&
            this.evaluacion.nss &&
            this.evaluacion.regimen &&
            this.evaluacion.direccion &&
            this.evaluacion.telefono &&
            this.evaluacion.tipo_de_contacto &&
            this.evaluacion.nombre_contacto &&
            this.evaluacion.supervisor &&
            this.evaluacion.unap
          ) {
            this.estadoLoading = "Asignando Turno al Paciente...";
            this.toggleLoading();

            this.evaluacion.fecha = this.formatDate(this.evaluacion.fecha);

            try {
              // alert("Bucando Order");
              const res = await getManyByDate(
                this.$store.state.user.desde,
                this.$store.state.user.id_center
              );
              this.evaluacion.order = res.data.response.Count + 1;
              // alert(this.evaluacion.order);
            } catch (error) {
              console.error(error);
              // alert(error);
            }

            this.evaluacion.id_centerKit = this.evaluacion.id_center;

            this.estadoLoading = "Creando Hoja de Evaluación...";

            try {
              const res = await create(this.evaluacion).then(
                async (res) => {
                  // this.cleanFields();
                  this.fillFields();
                  this.fixTime();
                  await this.toggleLoading();
                  this.estadoLoading = "Cargando...";
                  if (res.data.Status == "Success") {
                    this.$router.push("/evaluacions");
                  }
                },
                (err) => {
                  alert(err);
                }
              );
            } catch (error) {
              console.error(error);
            }

            document.getElementById(this.campoFocus).focus();
          } else {
            alert("Asegúrece de llenar todos los Campos");
          }
        } else {
          alert("Por Favor, Verifique el NSS");
          document.getElementById("nss").focus();
        }
      } else {
        if (this.evaluacion.nss.length == 9) {
          if (
            this.evaluacion.fecha &&
            this.evaluacion.cedula &&
            this.evaluacion.nombre &&
            this.evaluacion.apellido &&
            this.evaluacion.sexo &&
            this.evaluacion.fechaNacimiento &&
            this.evaluacion.edad &&
            this.evaluacion.nss &&
            this.evaluacion.regimen &&
            this.evaluacion.direccion &&
            this.evaluacion.telefono &&
            this.evaluacion.tipo_de_contacto &&
            this.evaluacion.nombre_contacto &&
            this.evaluacion.supervisor &&
            this.evaluacion.unap
          ) {
            this.toggleLoading();
            this.evaluacion.fecha = this.formatDate(this.evaluacion.fecha);

            this.estadoLoading = "Asignando Turno al Paciente...";
            await this.toggleLoading();

            try {
              // alert("Bucando Order");
              const res = await getManyByDate(
                this.$store.state.user.desde,
                this.$store.state.user.id_center
              );
              this.evaluacion.order = res.data.response.Count + 1;
              // alert(this.evaluacion.order);
            } catch (error) {
              console.error(error);
              // alert(error);
            }

            this.estadoLoading = "Creando Hoja de Evaluación...";

            this.evaluacion.id_centerKit = this.evaluacion.id_center;
            try {
              const res = await create(this.evaluacion).then(
                async (res) => {
                  // this.cleanFields();
                  this.fillFields();
                  this.fixTime();
                  await this.toggleLoading();
                  this.estadoLoading = "Cargando...";
                  if (res.data.Status == "Success") {
                    this.$router.push("/evaluacions");
                    this.showModalConfirmMethod();
                  }
                },
                (err) => {
                  alert(err);
                }
              );
            } catch (error) {
              console.error(error);
            }

            document.getElementById(this.campoFocus).focus();
          } else {
            alert("Asegúrece de llenar todos los Campos");
            // document.getElementById("cedula").focus();
          }
        } else {
          alert("Por Favor, Verifique el NSS");
          document.getElementById("nss").focus();
        }
      }
    },

    cleanFields() {
      this.evaluacion.nombre = "";
      this.evaluacion.apellido = "";
      this.evaluacion.sexo = "";
      this.evaluacion.cedula = "";
      this.evaluacion.nss = "";
      this.evaluacion.regimen = "";
      this.evaluacion.telefono = "";
      this.evaluacion.tipo_de_contacto = "";
      this.evaluacion.nombre_contacto = "";
      this.evaluacion.especificacion_contacto = "";
      this.evaluacion.region = "";
      this.evaluacion.provincia = "";
      this.evaluacion.municipio = "";
      this.evaluacion.estado = "";
      this.evaluacion.talla = "";
      this.evaluacion.peso = "";
      this.evaluacion.cintura = "";
      this.evaluacion.abdomen = "";
      this.evaluacion.cadera = "";
      this.evaluacion.altura_rodilla = "";

      this.evaluacion.presion_arterial_sistolica = "";
      this.evaluacion.presion_arterial_diatolica = "";
      this.evaluacion.frecuencia_cardiaca = "";
      this.evaluacion.fuerza = "";
      this.evaluacion.imc = "";
      this.evaluacion.flexibilidad_miembro_superior = "";
      this.evaluacion.flexibilidad_miembro_inferior = "";
      this.evaluacion.pliegue_bicep = "";
      this.evaluacion.pliegue_abdominal = "";
      this.evaluacion.pliegue_sub = "";
      this.evaluacion.Encamado = "";
      this.evaluacion.Edentulo = "";

      this.evaluacion.abandono = "";
      this.evaluacion.maltrato = "";

      this.evaluacion.specialist_appo_psiqui = "";
      this.evaluacion.specialist_appo_geria = "";
      this.evaluacion.specialist_appo_orto = "";
      this.evaluacion.specialist_appo_gastro = "";
      this.evaluacion.specialist_appo_oftal = "";
      this.evaluacion.specialist_appo_psicolo = "";
      this.evaluacion.specialist_appo_neurolo = "";
      this.evaluacion.specialist_appo_fisia = "";
      this.evaluacion.specialist_appo_cardio = "";
      this.evaluacion.specialist_appo_odonto = "";
      this.evaluacion.specialist_appo_urolo = "";
      this.evaluacion.specialist_appo_diabe = "";

      this.evaluacion.fecha = "";
      this.evaluacion.kit = "";
      this.evaluacion.nombre_doctor = "";
      this.evaluacion.comentary = "";
      this.evaluacion.eval_nutri_1 = "";
      this.evaluacion.eval_nutri_2 = "";
      this.evaluacion.eval_nutri_3 = "";
      this.evaluacion.eval_nutri_4 = "";
      this.evaluacion.eval_nutri_5 = "";
      this.evaluacion.eval_nutri_6 = "";
      this.evaluacion.eval_nutri_7 = "";
      this.evaluacion.eval_nutri_8 = "";
      this.evaluacion.eval_nutri_9 = "";
      this.evaluacion.eval_nutri_10 = "";
      this.evaluacion.fechaNacimiento = "";
      this.evaluacion.edad = "";
      this.evaluacion.direccion = "";
      this.evaluacion.status = "";
      this.evaluacion.id_center = "";
      this.evaluacion.id_patient = "";
      this.evaluacion.id_form = "";
      this.evaluacion.id_doctor = "";
      this.evaluacion.precio = "";

      this.evaluacion.autorizacion = "";
      this.evaluacion.pctoGrasa = "";
      this.evaluacion.estadoImc = "";
      this.evaluacion.estadoFuerza = "";
      this.evaluacion.riesgocardiovascular = "";
      this.evaluacion.estadofc = "";
      this.evaluacion.cambioKit = "";
      this.evaluacion.justificacionCambioKit = "";
      this.evaluacion.riesgoNutricional = "";
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    focus() {
      document.getElementById(this.campoFocus).focus();
    },
    focus2() {
      document.getElementById(this.campoFocus2).focus();
    },
  },
});
