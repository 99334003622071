
import { defineComponent } from "vue";
import { Evaluacion } from "@/interfaces/Evaluacion";
import {
  deleteEvaluacion,
  getEvaluacion,
  updateEvaluacion,
} from "@/services/elderstatistic/Evaluacion";
import numeral from "numeral";
import moment from "moment";

export default defineComponent({
  name: "evaluacion-list",
  data() {
    return {
      titulo: "",
      fechaActual: new Date(),
      cargando: false,
      evaluacion: {} as Evaluacion,
    };
  },
  methods: {
    formatDate(dateValue: Date) {
      let out = moment(dateValue).add(0, "days");
      return moment(out).format("D/MM/yyyy");
    },

    numeroAFecha(numeroDeDias: any, esExcel = false) {
      var diasDesde1900 = esExcel ? 25567 + 1 : 25567 + 2;

      // 86400 es el número de segundos en un día, luego multiplicamos por 1000 para obtener milisegundos.
      return new Date((numeroDeDias - diasDesde1900) * 86400 * 1000);
    },

    toPrint() {
      window.print();
    },
    toggleLoading() {
      this.cargando = !this.cargando;
    },
    formatSecuence(value: number) {
      return numeral(value).format("00000000");
    },

    formatNumber(value: number) {
      return numeral(value).format("0,0.00");
    },

    formatNumber2(value: number) {
      return numeral(value).format("00000000");
    },

    // formatDate(dateValue: Date) {
    //   let out = moment(dateValue).add(0, "h");
    //   return moment(out).format("D/MM/yyyy");
    // },

    formatTime(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("hh:mm a");
    },

    formatDate2(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("D/MM/yyyy");
    },

    async loadEvaluacion(id: string) {
      this.toggleLoading();
      try {
        const { data } = await getEvaluacion(id);
        this.evaluacion = data;
      } catch (error) {
        //console.error(error);
      }
      await this.toggleLoading();
      this.toPrint();

      if (this.$store.state.ids.length == 0) {
        this.$router.push(`/dashboard`);
      } else {
        this.$router.push(`/evaluacionslot/new`);
      }

      this.$router.push(`/evaluacions/${this.evaluacion._id}`);
    },
    async handleUpdate() {
      try {
        if (typeof this.$route.params.id === "string") {
          await updateEvaluacion(this.$route.params.id, this.evaluacion);
          this.$router.push("/evaluacions");
        }
      } catch (error) {
        //console.error(error);
      }
    },
    async handleDelete() {
      try {
        if (typeof this.$route.params.id === "string") {
          deleteEvaluacion(this.$route.params.id);
          this.$router.push("/evaluacions");
        }
      } catch (error) {
        //console.error(error);
      }
    },
  },
  mounted() {
    this.evaluacion = this.$store.state.user.defaultStatus[
      this.$route.params.index
    ];
    this.evaluacion.fecha = this.numeroAFecha(this.evaluacion.fecha);
    this.evaluacion.fecha = this.formatDate(this.evaluacion.fecha);
  },

  updated() {
    if (this.evaluacion.tipo == "med") {
      this.titulo = "Reclamación por Honorarios Médicos";
    } else {
      this.titulo = "Reclamación por Servicios";
    }
  },

  // beforeUnmount() {
  //   this.toPrint();
  // }
});
