import axios, { AxiosInstance } from "axios";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: "https://dfyh0sdnq9.execute-api.us-east-1.amazonaws.com/dev",
  headers: {
    "Content-Type": "application/json",
    "x-api-key": "OowfFuehC48RQnq3Mxbxq4N55unwnBuo1irgcdOd",
    
  },
});
export default axiosInstance;
