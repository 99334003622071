
import Filters from "@/components/Evaluacion/FiltersComponent.vue";
import ListadoComponent from "@/components/Evaluacion/ListComponent.vue";
import Navbar from "@/components/Navbar.vue";
import moment from "moment";
import { Evaluacion } from "@/interfaces/Evaluacion";
import { getManyByDate } from "@/services/evaluacion/Evaluacion";
import { getAll } from "@/services/centro/Centro";
import Pusher from "pusher-js";

export default {
  name: "App",

  components: {
    Filters,
    ListadoComponent,
    Navbar,
  },

  data() {
    return {
      searchField: "",
      showModalAseguradoras: false,
      filteredCentros: [] as any,
      centros: [] as any,
      criterio: {} as any,
      navMenuClas2: ["menu-navegacion2"],
      player: new Audio(),
      showModal: false,
      cargando: false,
      evaluacions: [] as Evaluacion[],
      evaluacionsFiltradas: [] as Evaluacion[],
      str: "",
      type: "",
    };
  },

  methods: {
    setCentro(centro: any) {
      this.$store.state.user.currentCentro = centro;
      this.$store.state.user.unap = centro.unap;
      this.$store.state.user.region = centro.region;
      this.$store.state.user.provincia = centro.provincia;
      this.$store.state.user.municipio = centro.municipio;
      this.$store.state.user.id_center = centro.id_center;
      this.$store.state.user.id_center = centro.id_center;
      this.loadEvaluacions();
      this.showModalAseguradorasMethod();
      this.search = "";
      this.lockCentro = true;
    },

    buscar(term: string) {
      this.$store.state.user.filteredCentros = this.$store.state.user.centros;
      if (term !== "") {
        this.$store.state.user.filteredCentros = this.$store.state.user.centros.filter(
          (centro: any) => {
            return centro.unap
              .toLowerCase()
              .includes(this.searchField.toLowerCase());
          }
        );
      }
    },

    showModalAseguradorasMethod() {
      this.showModalAseguradoras = !this.showModalAseguradoras;
    },

    async loadServsByEsp() {
      this.toggleLoading();
      this.toggleLoading();
    },

    fixTime() {
      if (this.criterio.desde) {
        this.criterio.desde = this.formatDateToFix(this.criterio.desde, false);
      }
      if (this.$store.state.user.desde) {
        this.$store.state.user.desde = this.formatDateToFix(
          this.$store.state.user.desde,
          false
        );
      }
    },

    formatDateToFix(dateValue: Date) {
      let out = moment(dateValue).add(0, "h");
      return moment(out).format("yyyy-MM-DD");
    },

    hamburger(texto: string) {
      switch (texto) {
        case "Aseguradoras":
          this.$router.push("/aseguradoras");
          break;
        case "Especialistas":
          this.$router.push("/especialistas");
          break;
        case "Evaluacions":
          this.$router.push("/evaluacions");
          break;
        case "Productos":
          this.$router.push("/productos");
          break;
        case "Dashboard":
          this.$router.push("/dashboard");
          break;
        case "Usuarios":
          this.$router.push("/usuarios");
          break;
        case "Mejoras":
          this.$router.push("/mejoras");
          break;
        case "Cerrar Sesión":
          this.salir();
          break;
      }
      if (this.navMenuClas.length == 1) {
        this.navMenuClas.push("spread");
        this.desplegar = "fas fa-caret-up";
      } else {
        this.navMenuClas.pop();
        this.desplegar = "fas fa-caret-down";
      }
    },

    pusherSubscribe() {
      // Start pusher subscribe
      var pusher = new Pusher("d7b50b87118775ed0b11", {
        cluster: "us2",
      });

      var channel = pusher.subscribe("my-channel");
      channel.bind("my-event", (data: any) => {
        this.loadEvaluacions2();
        // this.player.src = this.song.src;
        // this.player.play();
      });
      // End pusher subscribe
    },

    showModalMethod() {
      this.showModal = !this.showModal;
    },

    toggleLoading() {
      this.cargando = !this.cargando;
    },

    async search(term: string) {
      this.toggleLoading();

      this.toggleLoading();
    },

    sortEvaluacions() {
      if (this.$store.state.user.orden == "ASCENDENTE") {
        this.evaluacionsFiltradas = this.evaluacionsFiltradas.sort(
          (a: any, b: any) => a.order - b.order
        );
      } else {
        this.evaluacionsFiltradas = this.evaluacionsFiltradas.sort(
          (a: any, b: any) => b.order - a.order
        );
      }
    },

    async loadCentros() {
      this.toggleLoading();
      try {
        const res = await getAll();
        this.$store.state.user.centros = res.data.centros;
        this.$store.state.user.filteredCentros = res.data.centros;
      } catch (error) {
        console.error(error);
      }

      // this.sortEvaluacions();

      this.toggleLoading();
    },

    async loadEvaluacions() {
      this.$store.state.user.desde = this.criterio.desde;
      this.criterio.id_centerKit = this.$store.state.user.id_center;
      // this.criterio.id_centerKit = "303"
      this.toggleLoading();
      try {
        const res = await getManyByDate(
          this.criterio.desde,
          this.criterio.id_centerKit
        );
        this.evaluacions = res.data.response.Items;
        this.evaluacionsFiltradas = res.data.response.Items;
      } catch (error) {
        console.error(error);
      }

      this.sortEvaluacions();

      this.toggleLoading();
    },
  },

  mounted() {
    if (!this.$store.state.user.id_center) {
      this.loadCentros();
      this.showModalAseguradorasMethod();
    }

    if (!this.$store.state.user.orden) {
      this.$store.state.user.orden = "ASCENDENTE";
    }

    if (!this.$store.state.user.desde) {
      // alert('No hay fecha')
      this.criterio.desde = new Date();
      this.$store.state.user.desde = new Date();
      this.fixTime();
    } else {
      // alert('Si hay fecha')
      this.criterio.desde = this.$store.state.user.desde;
      this.loadEvaluacions();
    }
  },
};
